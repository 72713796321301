<template>
  <div
    class="knowledge-edit-wrap"
    v-loading="loading"
    element-loading-text="Loading"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.7)"
  >
    <edit-top
      ref="editTop"
      :document-info="documentInfo"
      :checked-node="checkedNode"
      :user-list-now="userListNow"
      :sending="sending"
      @saveAsVersion="postVersion"
      v-on="$listeners"
    ></edit-top>
    <div class="knowledge-edit-main">
      <Catalogue
        v-if="documentInfo && !tiptap"
        ref="catalogue"
        :document-info="documentInfo"
        :editable="true"
      ></Catalogue>
      <edit-wrap
        v-if="documentInfo && !tiptap"
        ref="editwrap"
        :document-info="documentInfo"
        :save-as-version="isSaveAsVersion"
        @contentChange="contentChange"
        @userlistchange="userlistchange"
        @focusTitle="focusTitle"
        @saveVersionStatus="postVersionComplete"
        @sendingFn="sendingFn"
        @loading="loading1"
      ></edit-wrap>

      <zdheditor
        v-if="tiptap"
        style="flex: 1"
        :key="$route.fullPath"
      ></zdheditor>

      <rightTemplate></rightTemplate>
    </div>
    <div class="goTop">
      <i class="iconfont icon-a-huaban18" @click="goTop"></i>
    </div>
  </div>
</template>

<script>
import zdheditor from "@/views/tiptap";
import editTop from "./editTop.vue";
import Catalogue from "../catalogue.vue";
import editWrap from "./editWrap.vue";
import rightTemplate from "./rightTemplate.vue";
import { getContent, postDocumentVersion } from "@/network/knowledge";
export default {
  components: {
    editTop,
    Catalogue,
    editWrap,
    rightTemplate,
    zdheditor,
  },
  props: {
    checkedNode: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      documentInfo: null,
      userListNow: [],
      isSaveAsVersion: false,
      sending: false,
      loading: false,
      tiptap: false,
    };
  },
  created() {
    console.log();
    if (
      localStorage.getItem("open_tiptap") &&
      localStorage.getItem("open_tiptap") == "true"
    ) {
      this.tiptap = true;
    }
  },
  mounted() {
    if (!this.checkedNode) return;
    const params = {
      projectId: this.get_pid(),
      documentId: this.checkedNode ? this.checkedNode.documentId : "",
    };
    getContent(params).then((res) => {
      this.documentInfo = res;
    });
    const goTop = document.getElementsByClassName("goTop")[0];
    goTop.style.display = "none";
  },
  methods: {
    contentChange() {
      this.$refs.catalogue.updateTree();
    },
    userlistchange(list) {
      this.userListNow = list;
    },
    focusTitle() {
      this.$refs.editTop.focusTitle();
    },
    postVersion() {
      this.isSaveAsVersion = true;
    },
    postVersionComplete() {
      this.isSaveAsVersion = false;
    },
    sendingFn(flag) {
      this.sending = flag;
    },
    loading1() {
      this.loading = false;
    },
    goTop() {
      const doc = document.getElementById("editor-container-wrap");
      if (doc) {
        doc.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.knowledge-edit-wrap {
  width: 100%;
  height: 100%;
  .knowledge-edit-main {
    width: 100%;
    height: calc(100% - 100px);
    display: flex;
    justify-content: left;
    padding-bottom: 10px;
  }
  // .knowledge-edit-box {
  //   display: flex;
  //   width: 100%;
  //   height: 89%;
  //   & > .knowledge-edit-main {
  //     width: 100%;
  //     height: calc(100% - 100px);
  //     display: flex;
  //     justify-content: left;
  //     padding-bottom: 10px;
  //   }
  // }
}
.goTop {
  position: absolute;
  bottom: 50px;
  right: 30px;
  border-radius: 50%;
  background-color: white;
  i {
    cursor: pointer;
    font-size: 30px;
    font-weight: 600;
    color: rgba(145, 147, 153, 1);
  }
}
</style>
