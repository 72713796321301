<template>
  <div
    id="no-hidden-dialog"
    style='overflow:auto'
    @scroll="warpScroll"
  >
        <div id="document-wrap" class="document-wrap">
        <el-img-viewer
          v-if="isViewerVisible"
          class="viewer"
          :url-list="nowImgsrc"
          :on-close="closeImgViewer"
        ></el-img-viewer>
        <!-- <div id="document-content" v-if="show_tiptap">
          <div @click="checktext">
          <zdheditor @click='click_zdh_edit' :key="$route.fullPath"></zdheditor>

          </div>
        </div> -->

        <div id="document-content" @click="showImage" >
          <div v-html="dividedHtml" @click="checktext" v-if="show_tiptap" id="show-tiptap-docunent-zdh"></div>
          <div v-html="dividedHtml" @click="checktext" v-else></div>
          
        </div>
        <div id="hide-wrap" class="hide-wrap"></div>
        <div
          v-for="(item, index) in commentInline"
          :key="index"
          style="
            position: absolute;
            margin-left: -1.5%;
            transform: translateX(-50%);
          "
          :style="getPosition(item)"
        >
          <i
            v-if="!item.active"
            class="iconfont icon-comment_inline_g"
            style="
              font-size: 20px;
              margin-right: 10px;
              cursor: pointer;
              color: rgb(127, 127, 128);
            "
            @click="filterRight(item)"
          />
          <i
            v-else
            class="iconfont icon-comment_inline_g"
            style="
              font-size: 20px;
              margin-right: 10px;
              cursor: pointer;
              color: rgb(48, 100, 143);
            "
            @click="clearfilterRight(item)"
          />
        </div>
        <bottom-comment
          v-if="checkedNode"
          :updated-at="updatedAt"
          :checked-node="checkedNode"
          :updated-by="updatedBy"
          :created-by="createdBy"
        ></bottom-comment>
        <div v-if="showMindStatus">
          <mindStatus
            v-for="(item, index) in mindData"
            :key="index"
            :outer-width="outerWidth"
            :is-document="true"
            :mind="item"
            :status-list="statusList"
          ></mindStatus>
        </div>
        <div id="menu">
          <div>
            <div
              v-for="(item, index) in isPublic ? rightMenuPub : rightMenu"
              :key="index"
              @click="menuClick(index)"
            >
              {{ item.label }}
            </div>
          </div>
        </div>
      </div>
    <label style="display: block">
      <textarea ref="text_area" style="opacity: 0"></textarea>
    </label>
    <el-dialog
      :title="$t('knowledge.WordExtractComment')"
      :visible.sync="commentDialogVisible"
      :close-on-click-modal="false"
      :show-close="true"
      width="25%"
    >
      <div id="quill2" style="position: relative">
        <div id="editor-container2"></div>
        <div
          v-if="atShow"
          class="at_position"
          :style="{
            left: atShowposition.left - 50 + 'px',
            top: atShowposition.top + 30 + 'px',
          }"
        >
          <at-vue
            ref="atVue"
            @selectPerson="selectPerson"
            :queryString="atQuery"
          ></at-vue>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            commentDialogVisible = false;
            atShow = false;
          "
          >{{ $t("review.tip13") }}</el-button
        >
        <el-button type="primary" @click="commentDialogComment">{{
          $t("knowledge.Comment")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getContent } from "@/network/knowledge";
import zdheditor from "@/views/tiptap";
import bottomComment from "./bottomComment.vue";
import mindStatus from "./mindStatus.vue";
import Quill from "quill";
import { distribution, sendComments } from "@/network/knowledge";
import AtVue from "../edit/At.vue";
import QuillBetterTable from "@/plugins/quill-better-table.js";
import { internetShare } from "@/network/knowledge";
export default {
  components: {
    zdheditor,
    mindStatus,
    "el-img-viewer": () => import("element-ui/packages/image/src/image-viewer"),
    bottomComment,
    AtVue,
  },
  props: {
    checkedNode: {
      type: Object,
      default() {
        return null;
      },
    },
    rightCommentList: {
      type: Array,
      default() {
        return [];
      },
    },
    statusList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      quill: null,
      quill2: null,
      // 目前选中的行id
      lineId: "",
      data: "",
      showMindStatus: false,
      commentDialogVisible: false,
      outerWidth: 0,
      mindData: [],
      nowImgsrc: [],
      atShow: false,
      atQuery: "",
      isQuerying: false,
      atShowposition: {
        left: 0,
        top: 0,
      },
      rightMenu: [
        {
          label: this.$t("knowledge.WordExtractComment"),
        },
        {
          label: this.$t("knowledge.internalSharingLinks"),
        },
      ],
      rightMenuPub: [
        {
          label: this.$t("knowledge.WordExtractComment"),
        },
        {
          label: this.$t("knowledge.internalSharingLinks"),
        },
        {
          label: this.$t("knowledge.externalSharingLinks"),
        },
      ],
      isPublic: false,
      isViewerVisible: false,
      updatedAt: "",
      updatedBy: "",
      createdBy: "",
      commentInline: [],
      // 上次定位的id
      lastHash: "",
      wrapScrollTop: 0,
      show_tiptap: false,
    };
  },
  computed: {
    dividedHtml() {
      // const dividedHtml = DOMPurify.sanitize(this.data);
      const dividedHtml = this.data;
      return dividedHtml;
    },
  },
  watch: {
    $route(val) {
      if (val.hash) {
        this.locationElement(val.hash);
      }
    },
    rightCommentList(newvale) {
      this.commentInline = [];
      newvale.forEach((item) => {
        item.active = false;
        item.lineId ? this.commentInline.push(item) : "";
      });
      this.locationRightComment();
    },
    checkedNode: {
      handler: function (newVal, oldval) {
        if (!(newVal && oldval && newVal.documentId === oldval.documentId)) {
          this.gethtml(newVal ? newVal.documentId : "");
        }
        this.getMindData();
      },
      immediate: true,
    },
  },
  mounted() {
    this.show_tiptap = JSON.parse(
      localStorage.getItem("open_tiptap")
        ? localStorage.getItem("open_tiptap")
        : "false"
    );
    this.initQuill();
    // 监听dom变化
    this.listenerwidth();
    this.initRightClick();
    let checkIframesInterval;

    function checkIframes() {
      let iframes = document.querySelectorAll("iframe");
      if (iframes.length > 0) {
        iframes.forEach((ele) => {
          let parentNode = ele.parentNode;
          let parentParent = parentNode.parentNode;

          if (parentNode.tagName.toLowerCase() === "p") {
            parentNode.style.overflow = "scroll";
          } else if (parentParent.tagName.toLowerCase() === "p") {
            parentParent.style.overflow = "scroll";
          }
        });

        clearInterval(checkIframesInterval);
      }
    }

    checkIframesInterval = setInterval(checkIframes, 1000);
  },
  methods: {
    click_zdh_edit(){
      console.log(4444);
      
    },
    checktext(event) {
      let selection = window.getSelection();
      let range = selection.getRangeAt(0);
      let commonAncestor = range.commonAncestorContainer;
      console.log(selection, range, commonAncestor);

      if (
        selection.toString() != "" &&
        (commonAncestor.id == undefined || commonAncestor.id != "")
      ) {
        this.setDownposition();
        event.stopPropagation();
      }
    },
    // 过滤右侧评论
    clearfilterRight(params) {
      this.$set(params, "active", false);
      this.commentInline = this.commentInline.concat([]);
      this.$emit("filterRight", false);
    },
    filterRight(params) {
      this.commentInline.forEach((item) => {
        this.$set(item, "active", false);
        item.active = false;
      });
      this.$set(params, "active", true);
      this.commentInline = this.commentInline.concat([]);
      this.$emit("filterRight", params.lineId);
    },
    // 获取左侧图标位置
    getPosition(item) {
      const dom = document.getElementById(item.lineId);
      return {
        display: dom ? "block" : "none",
        top: dom ? dom.offsetTop + "px" : "0",
        left: "0.2375rem",
      };
    },
    initRightClick() {
      const menu = document.getElementById("menu");
      const wrap2 = document.getElementById("document-wrap");
      const self = this;
      const wrap = document.getElementById("document-content");
      wrap.oncontextmenu = function (evt) {
        self.setDownposition(evt);
      };
      wrap2.onclick = function () {
        self.lineId
          ? (document.getElementById(self.lineId).style.backgroundColor =
              "#fff")
          : "";
        menu.style.display = "none";
      };
    },
    setDownposition(evt) {
      const wrap = document.getElementById("document-content");
      const self = this;
      const menu = document.getElementById("menu");
      // 自定义body元素的鼠标事件处理函数
      const e = evt || window.event;
      e.preventDefault(); //阻止系统右键菜单
      // 显示自定义的菜单调整位置
      self.lineId
        ? (document.getElementById(self.lineId).style.backgroundColor = "#fff")
        : "";
      self.lineId = e.target.id ? e.target.id : e.target.parentNode.id;
      document.getElementById(self.lineId).style.backgroundColor = "#f0f7ff";
      if (self.lineId) {
        menu.style.display = "block";
        menu.style.left = e.layerX + "px";
        menu.style.top = e.layerY + wrap.scrollTop + "px";
      }
    },
    //粘贴方法
    pasteFile(evt) {
      // 获取粘贴的html 和text 并判断是否包含img
      const html = evt.clipboardData.getData("text/html");
      const hasImg = /<img\b[^>]*>/.test(html);
      const text = evt.clipboardData.getData("text/plain");
      if (!hasImg) {
        // 判断是否是网络链接
        let re = new RegExp("^(http|https)://", "i");
        const isHttp = re.test(text);
        const index = this.quill2.getSelection().index;
        if (isHttp) {
          evt.preventDefault();
          this.quill2.insertEmbed(index, "link", {
            href: text,
            target: "_blank",
            innerText: text,
          });
          this.quill2.setSelection(index + text.length + 1);
        } else {
          return true;
        }
      }
    },
    // 右键菜单点击事件
    menuClick(index) {
      switch (index) {
        case 0:
          if (this.lineId) {
            this.commentDialogVisible = true;
            this.$nextTick(() => {
              this.quill2 = new Quill("#editor-container2", {
                modules: {
                  toolbar: false,
                  history: {
                    delay: 2000,
                    maxStack: 500,
                  },
                  ImageResize: {
                    modules: ["Resize", "DisplaySize"],
                    displayStyles: {
                      backgroundColor: "black",
                      border: "none",
                      color: "white",
                    },
                  },
                },
                theme: "snow",
              });
              this.quill2.setContents([]);
              this.quill2.on("text-change", this.text_change);
              this.quill2.root.addEventListener("paste", this.pasteFile, true);
            });
          }
          break;
        case 1:
          if (this.lineId) {
            const link = window.location.href.split("?")[0];
            const query = `?documentId=${this.checkedNode.documentId}&elementId=${this.lineId}`;
            let text1 = this.$refs["text_area"];
            text1.innerText = link + query;
            text1.select();
            document.execCommand("copy");
            this.$message({
              type: "success",
              message: this.$t("knowledge.copiedLink"),
            });
          }
          break;
        case 2:
          if (this.lineId) {
            const params = {
              projectId: this.get_pid(),
              documentId: this.checkedNode.documentId,
            };
            internetShare(params).then((res) => {
              const url = res.url;
              // const id = url.split("/")[url.split("/").length - 1];
              const link = url + "?elementId=" + this.lineId;
              let text1 = this.$refs["text_area"];
              text1.innerText = link;
              text1.select();
              document.execCommand("copy");
              this.$message({
                type: "success",
                message: this.$t("knowledge.copiedLink"),
              });
            });
          }
          break;
      }
    },
    atPosition() {
      const selection = this.quill2.getSelection();
      const bounds = this.quill2.getBounds(selection.index, selection.length);
      this.atShowposition = bounds;
    },
    // 选择用户
    selectPerson(item) {
      const index = this.quill2.getSelection().index - this.atQuery.length;
      this.atShow = false;
      this.isQuerying = false;
      this.quill2.deleteText(index - 1, 1 + this.atQuery.length);
      this.quill2.insertEmbed(index - 1, "my-span", {
        name: "@" + item.nickname,
        accountId: item.accountId,
      });
      this.quill2.insertEmbed(index + item.nickname.length, "text", " ");
      setTimeout(
        () =>
          this.quill2.setSelection(
            this.quill2.getSelection().index + item.nickname.length + 2,
            0
          ),
        100
      );
    },
    // 监听富文本的变化
    text_change(delta, oldDelta, source) {
      delta.ops.forEach((item) => {
        if (item.insert === "@") {
          // atFlag = true;
          this.isQuerying = true;
          this.atQuery = "";
          this.atPosition();
        } else if (this.isQuerying) {
          if (item.insert == "\n" || item.insert == " ") {
            this.isQuerying = false;
          } else if (item.insert) {
            this.atQuery += item.insert;
          } else if (item.delete) {
            if (item.delete > this.atQuery.length) {
              this.isQuerying = false;
            } else {
              this.atQuery = this.atQuery.slice(0, -item.delete);
            }
          }
        }
      });
      this.atShow = this.isQuerying;
    },
    // 确定评论
    commentDialogComment() {
      if (this.quill2.getContents().ops.length > 1) {
        const plainText = this.quill2.getText(0);
        const content = JSON.stringify(this.quill2.getContents().ops);
        const atList = [];
        const type = "line";
        const parentId = "";
        const lineId = this.lineId;
        this.quill2.getContents().ops.forEach((item) => {
          if (item.attributes && item.attributes["my-span"]) {
            atList.push(item.attributes["my-span"].accountId);
          }
        });
        const params = {
          projectId: this.get_pid(),
          documentId: this.checkedNode.documentId,
          data: {
            plainText,
            content,
            lineId,
            type,
            atList,
            parentId,
          },
        };
        sendComments(params).then((res) => {
          this.commentDialogVisible = false;
          this.$emit("getComments");
        });
      } else {
        this.$message({
          type: "warning",
          message: this.$t("knowledge.CommentNotEmpty"),
        });
      }
    },
    // 定位参数dom
    locationElement(hash) {
      if ((this.$route.query && this.$route.query.elementId) || hash) {
        this.lastHash
          ? document
              .getElementById(this.lastHash)
              .classList.remove("position_dom")
          : "";
        const id = hash ? hash.split("#")[1] : this.$route.query.elementId;
        const dom = document.getElementById(id);
        if (dom) {
          dom.classList.add("position_dom");
        }
        window.location.href = "#" + id;
        this.lastHash = id;
      }
    },
    // 定位右侧评论
    locationRightComment() {
      if (this.$route.query && this.$route.query.commentId) {
        for (let i = 0; i < this.commentInline.length; i++) {
          if (this.commentInline[i].commentId === this.$route.query.commentId) {
            this.filterRight(this.commentInline[i]);
            return;
          }
        }
      }
    },
    showImage(event) {
      if (event.target.localName === "img") {
        this.nowImgsrc = [event.target.currentSrc];
        this.isViewerVisible = true;
      }
      if (event.target.id.split("#")[0] === "link") {
        let l = event.target.attributes.link.value;
        window.open(
          (l.slice(0, 4) == "http" ? "" : "//") +
            event.target.attributes.link.value,
          "_blank"
        );
      }
    },
    closeImgViewer() {
      this.isViewerVisible = false;
    },
    // 监听宽度变化
    listenerwidth() {
      const self = this;
      this.observer = new ResizeObserver((entries) => {
        entries.forEach((item) => {
          self.outerWidth = item.borderBoxSize[0].inlineSize;
        });
      });
      let content = document.getElementById("document-content");
      this.observer.observe(content);
    },
    // 获取思维导图数据
    getMindData() {
      this.mindData = [];
      if (!this.checkedNode) return;
      const params = {
        projectId: this.get_pid(),
        documentId: this.checkedNode.documentId,
      };
      distribution(params).then((res) => {
        this.mindData = res.filter((item) => {
          return !item.fileKey;
        });
        const titleMindData = res.filter((item) => {
          return item.fileKey;
        });
        this.$emit("titleMindChange", titleMindData);
      });
    },
    creatLoading() {
      this.loading = this.$loading({
        target: "#knowledge-home-wrap",
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
    },
    initQuill() {
      this.quill = new Quill("#hide-wrap", {
        modules: {
          toolbar: false,
          history: {
            delay: 2000,
            maxStack: 500,
          },
          ImageResize: {
            displayStyles: {
              backgroundColor: "black",
              border: "none",
              color: "white",
            },
          },
          table: false, // disable table module
          "better-table": {
            operationMenu: {
              items: {
                insertColumnRight: {
                  text: this.$t("knowledge.InsertRight"),
                },
                insertColumnLeft: {
                  text: this.$t("knowledge.InsertLeft"),
                },
                insertRowUp: {
                  text: this.$t("knowledge.InsertAbove"),
                },
                insertRowDown: {
                  text: this.$t("knowledge.InsertBelow"),
                },
                mergeCells: {
                  text: this.$t("knowledge.MergeCells"),
                },
                unmergeCells: {
                  text: this.$t("knowledge.SplitCells"),
                },
                deleteColumn: {
                  text: this.$t("knowledge.DeleteColumn"),
                },
                deleteRow: {
                  text: this.$t("knowledge.DeleteRow"),
                },
                deleteTable: {
                  text: this.$t("knowledge.DeleteTable"),
                },
              },
            },
          },
          keyboard: {
            bindings: QuillBetterTable.keyboardBindings,
          },
        },
        theme: "snow",
      });
    },
    gethtml(id) {
      const self = this;
      this.showMindStatus = false;
      if (id) {
        const params = {
          projectId: this.get_pid(),
          documentId: id,
        };
        this.loading ? "" : this.creatLoading();
        getContent(params).then((res) => {
          this.updatedAt = res.updatedAt;
          this.updatedBy = res.updatedBy;
          this.createdBy = res.createdBy;
          this.loading.close();
          // 点击查看at的时候
          if (this.$route.query["elementId"]) {
            if (!res.content.includes(this.$route.query["elementId"])) {
              this.$message({
                message: this.$t("knowledge.delete_at"),
                type: "warning",
              });
            }
          }
          console.log(666);
          
          if (res.content || res.contentHtml) {
            if (this.show_tiptap) {
              self.data = res.contentHtml;
            } else {
              const delta = JSON.parse(res.content);
              self.quill.setContents(delta, "api");
              self.data = self.quill.root.innerHTML;
              // console.log(self.quill.root.innerHTML);
            }

            self.$emit("exportHtml", self.data);
            self.$nextTick(() => {
              self.locationElement();
              setTimeout(() => {
                self.showMindStatus = true;
              }, 2000);
            });
          } else {
            self.data = "";
          }
        });
      } else {
        this.data = "";
      }
    },
    warpScroll(e) {
      this.wrapScrollTop = e.target.scrollTop;
    },
    getWrapScrollTop() {
      return this.wrapScrollTop;
    },
  },
  provide() {
    return {
      wrapScrollTop: this.getWrapScrollTop,
    };
  },
};
</script>

<style scoped lang="scss">
#document-content {
  min-height: 40vh;
  margin-top: 25px;
}
.at_position {
  position: absolute;
}
#toolbar-container2 {
  display: none;
}
#editor-container2 {
  width: 100%;
  border-top: 1px solid #ccc;
}
.ql-snow,
.ql-edit {
  width: 65%;
  flex-grow: 1;
  overflow-y: scroll;
  .ql-editor {
    height: fit-content;
    padding-right: 0px;
  }
}
.document-wrap {
  width: 100%;
  padding-left: 30px;
  padding-right: 34px;
  padding-bottom: 40px;
  text-align: left;
  overflow-x: visible;
  position: relative;
  overflow-y: visible;
  .hide-wrap {
    display: none;
  }
  .toolbar-container {
    display: none;
  }
}
#menu {
  display: none;
  position: absolute;
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.25);
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  padding: 10px;
  div {
    div {
      height: 40px;
      line-height: 40px;
      color: rgba(48, 100, 143, 1);
      font-size: 12px;
      text-align: center;
      cursor: default;
      &:hover {
        background: rgba(229, 229, 229, 0.61);
        cursor: pointer;
      }
    }
  }
}
::v-deep .my-alink,
::v-deep .my-atitle,
::v-deep .my-adesc,
::v-deep .my-acard_link {
  cursor: pointer;
}
</style>
<style lang="scss">
.no-hidden-dialog {
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 10px;
    height: 8px;
    background-color: #ebeef5;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(207, 207, 207, 1);
    border-radius: 4px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 3px;
    background: rgba(255, 255, 255, 1);
  }
  .el-dialog {
    overflow: visible;
  }
}
.document-wrap {
  & > div {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      padding-right: 200px !important;
      /*no*/
    }
  }
}
#show-tiptap-docunent-zdh {
  font-size: 15px;
  text-align: left;
  color: rgb(23, 23, 23);
  font-family: 'Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"';

  .mention {
    font-size: 13px;
    background-color: #a9d5ff;
    border-radius: 4px;
    box-decoration-break: clone;
    color: #1873cc;
    padding: 1px 5px;
  }
  img {
    // display: block;
    height: auto;
    // margin: 1.5em 0;
    max-width: 100%;
    width: 500px;

    &.ProseMirror-selectednode {
      outline: 3px solid #0064ff;
    }
  }
  ul[data-type="taskList"] {
    list-style: none;
    margin-left: 0;
    padding: 0;
    p {
      margin: 5px 0;
    }
    li {
      align-items: flex-start;
      display: flex;
      align-items: center;

      > label {
        margin-right: 0.5em;
        user-select: none;
        width: 20px;
        input {
          // background: red;
        }
      }

      > div {
      }
    }

    input[type="checkbox"] {
      cursor: pointer;
    }

    ul[data-type="taskList"] {
      margin: 0;
    }
  }
  .collaboration-cursor__caret {
    border-left: 1px solid #0d0d0d;
    border-right: 1px solid #0d0d0d;
    margin-left: -1px;
    margin-right: -1px;
    pointer-events: none;
    position: relative;
    word-break: normal;
  }

  .collaboration-cursor__label {
    border-radius: 3px 3px 3px 0;
    color: #0d0d0d;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    left: -0.5px;
    line-height: normal;
    padding: 5px 3px;
    position: absolute;
    top: -2em;
    user-select: none;
    white-space: nowrap;
  }
  ul {
    padding-left: 25px;
  }
  ul li::marker {
    color: #0064ff;
  }
  ol {
    padding-left: 25px;
  }
  ol li::marker {
    color: #0064ff;
  }
  a {
    color: #0064ff;
    cursor: pointer;
    position: relative;

    &:hover {
      border-bottom: 2px solid #0064ff;
    }
  }

  hr {
    border: none;
    border-top: 1px solid #3d251414;
    border-top: 2px dashed #cccccc;
    cursor: pointer;
    margin: 10px 0;

    &.ProseMirror-selectednode {
      border-top: 1px solid #e3eeff;
    }
  }
  mark {
    // background-color: #faf594;
    border-radius: 0.4em;
    box-decoration-break: clone;
    padding: 0.1em 0.3em;
  }
  // 引用样式
  blockquote {
    margin: 0;
    border-left: 3px solid #e5e7eb;
    // margin: 1.5rem 0;
    padding-left: 10px;
    color: rgb(135, 136, 138);
    p {
      margin-top: 2.5px;
      margin-bottom: 2.5px;
    }
  }
  pre {
    background: black;
    border-radius: 5px;
    font-family: "Consolas, Monaco", "Andale Mono", "Ubuntu Mono", monospace;
    margin: 10px 0;
    color: white;
    padding: 20px;
    font-size: 14px;

    code {
      background: none;
      color: inherit;
      // font-size: 0.8rem;
      padding: 0;
    }
    /* Code styling */
    .hljs-comment,
    .hljs-quote {
      color: #616161;
    }

    .hljs-variable,
    .hljs-template-variable,
    .hljs-attribute,
    .hljs-tag,
    .hljs-name,
    .hljs-regexp,
    .hljs-link,
    .hljs-name,
    .hljs-selector-id,
    .hljs-selector-class {
      color: #f98181;
    }

    .hljs-number,
    .hljs-meta,
    .hljs-built_in,
    .hljs-builtin-name,
    .hljs-literal,
    .hljs-type,
    .hljs-params {
      color: #fbbc88;
    }

    .hljs-string,
    .hljs-symbol,
    .hljs-bullet {
      color: #b9f18d;
    }

    .hljs-title,
    .hljs-section {
      color: #faf594;
    }

    .hljs-keyword,
    .hljs-selector-tag {
      color: #70cff8;
    }

    .hljs-emphasis {
      font-style: italic;
    }

    .hljs-strong {
      font-weight: 700;
    }
  }
  /* Table-specific styling */
  table {
    border-collapse: collapse;
    margin: 0;
    overflow: hidden;
    table-layout: fixed;
    width: 100% !important;

    tr{
      height: 25px;
    }

    td,
    th {
      border: 1px solid #dfe1e5;
      box-sizing: border-box;
      min-width: 1em;
      padding: 6px 8px;
      position: relative;
      vertical-align: top;

      > * {
        margin: 0 !important;
      }
    }

    th {
      // background-color: gray;
      font-weight: bold;
      text-align: left;
    }

    .selectedCell:after {
      background: rgb(160 198 255 / 60%);
      content: "";
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      pointer-events: none;
      position: absolute;
      z-index: 2;
    }

    .column-resize-handle {
      background-color: #0064ff;
      bottom: -2px;
      pointer-events: none;
      position: absolute;
      right: -2px;
      top: 0;
      width: 4px;
    }
  }

  mark {
    border-radius: 0;
    padding: 0;
  }

  .tableWrapper {
    margin: 1.5em 0;
    overflow-x: auto;
  }

  &.resize-cursor {
    cursor: ew-resize;
    cursor: col-resize;
  }
}
</style>
<style>
.position_dom {
  background-color: rgb(255, 195, 0, 0.2);
}
.el-image-viewer__img {
  background-color: #fff;
}
</style>
