<template>
  <div class="mindstatus_wrap" :style="isDocument ? findPosition : ''">
    <el-tooltip
      class="item"
      effect="dark"
      :content="fileTypeInfo.name"
      placement="top-start"
    >
      <span class="fileTypeIcon">
        <i
          v-if="fileTypeInfo.icon !== '-1'"
          class="iconfont"
          :class="'icon-a-' + fileTypeInfo.icon"
          style="color: white;"
        ></i>
        <span
          v-else
          style="color: white;"
          >{{ fileTypeInfo.name.slice(0, 1) }}</span
        >
      </span>
    </el-tooltip>
    <el-tooltip
      class="item"
      effect="dark"
      :content="mind.nodeKey"
      placement="top"
    >
      <span class="key_style" @click="jump_node">{{ mind.nodeKey }}</span>
    </el-tooltip>

    <div class="status_wrap">
      <status-select
      style='width:100% !important;padding-right:5px'
        v-model="statusName"
        :node-key="mind.nodeKey"
        size="small"
        :status_type="getStatusCategory(mind.status)"
      ></status-select>
    </div>
    <div>
      <personal-avatar
        v-if="matchUserInfo(mind.assignee)"
        :avatar="matchUserInfo(mind.assignee).avatar"
        :colorid="matchUserInfo(mind.assignee).accountId"
        :nickname="matchUserInfo(mind.assignee).nickname"
        :size="18"
        style="margin-right: 5px"
      ></personal-avatar>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import StatusSelect from "@/components/statusSelect";
import PersonalAvatar from "@/components/personal";
export default {
  components: {
    StatusSelect,
    PersonalAvatar,
  },
  props: {
    mind: {
      type: Object,
      default() {
        return null;
      },
    },
    isDocument: {
      type: Boolean,
      default: false,
    },
    outerWidth: {
      type: Number,
      default: 0,
    },
    statusList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["status", "fileType"]),
    fileTypeInfo() {
      return this.fileType[this.mind.fileTypeId];
    },
    statusName() {
      return this.status[this.mind.status].name;
    },
    findPosition() {
      const node = document.getElementById(this.mind.lineId);
      if (!node) {
        return `display: none`;
      }
      // 模拟quill样式
      const qlSnow = document.createElement("div");
      qlSnow.classList.add("ql-snow");
      const qlEditor = document.createElement("div");
      qlEditor.classList.add("ql-editor");
      const newNode = node.cloneNode(true);
      newNode.style.display = "inline-block";
      qlEditor.append(newNode);
      qlSnow.append(qlEditor);
      document.body.append(qlSnow);
      const width = newNode.clientWidth;
      const height = node.clientHeight;
      let lineheight = 0;
      let statusHight = 0;
      let topTep = 0;
      if (height >= 24) {
        lineheight = 24;
        statusHight = 24;
        topTep = (height - statusHight) / 2;
      } else {
        lineheight = height;
        statusHight = height;
      }
      document.body.removeChild(qlSnow);
      return `top: ${node.offsetTop + topTep}px;${
        width + 20 + 200 >= this.outerWidth
          ? "right:10"
          : "left:" + (node.offsetLeft + width + 10)
      }px; height: ${statusHight}px; line-height:${lineheight}px`;
    },
  },
  async mounted() {},
  methods: {
    jump_node(item) {
      const { href } = this.$router.resolve({
        name: "node_detail",
        params: {
          projectId: this.get_pid(),
          nodeKey: this.mind.nodeKey,
        },
      });
      window.open(href, "_blank");
    },
    getStatusCategory(id) {
      return this.status[id] ? this.status[id].statusCategory : "";
    },
  },
};
</script>

<style lang="scss" scoped>
.mindstatus_wrap {
  /* prettier-ignore */
  min-height: 24PX;
  top: 2px;
  /*no*/
  /* prettier-ignore */
  // width: 200PX;
  /*no*/
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  background: rgba(204, 204, 204, 0.26);
  border-radius: 4px;
  color: rgba(42, 130, 228, 1);
  font-size: 12px;
  .fileTypeIcon{
      display: flex;
      justify-content: center;
      height: 24px;
      width: 27px;
      border-radius: 5px 0 0 5px;
      align-items: center;
      background-color: #014caa;
    }
  .status_wrap {
    display: flex;
    height: fit-content;
    padding-left: 6px;
    width: 46%;
  }
.key_style {
  color: #ffc300;
  min-width: 63px;
  padding-left: 2px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

}
</style>
