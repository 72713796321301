import { render, staticRenderFns } from "./rightComment.vue?vue&type=template&id=510e5dd8&scoped=true"
import script from "./rightComment.vue?vue&type=script&lang=js"
export * from "./rightComment.vue?vue&type=script&lang=js"
import style0 from "./rightComment.vue?vue&type=style&index=0&id=510e5dd8&prod&scoped=true&lang=scss"
import style1 from "./rightComment.vue?vue&type=style&index=1&id=510e5dd8&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "510e5dd8",
  null
  
)

export default component.exports