import { render, staticRenderFns } from "./knowledgeNotEdit.vue?vue&type=template&id=597d3650&scoped=true"
import script from "./knowledgeNotEdit.vue?vue&type=script&lang=js"
export * from "./knowledgeNotEdit.vue?vue&type=script&lang=js"
import style0 from "./knowledgeNotEdit.vue?vue&type=style&index=0&id=597d3650&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "597d3650",
  null
  
)

export default component.exports