<template>
  <div class="tool-wrap">
    <div style="margin-right: 20px">
      <el-button v-if="!open_tiptap" @click="goto_tiptap(true)">
        切换新版编辑器
      </el-button>

      <el-button v-else @click="goto_tiptap(false)"> 返回旧版 </el-button>
      <!-- <el-button @click="fix_table()"> 修复表格 </el-button> -->
    </div>

    <span>
      <el-tooltip class="item" effect="dark" placement="bottom">
        <div slot="content">{{ $t("knowledge.RightClickInfo") }}</div>
        <i
          class="iconfont icon-wenhao1"
          style="
            font-size: 28px;
            vertical-align: middle;
            color: rgba(128, 128, 128, 1);
            margin-right: 20px;
          "
        ></i>
      </el-tooltip>
    </span>
    <el-badge
      :value="questionNumber"
      class="question-badge"
      :max="99"
      :hidden="!questionNumber"
    >
      <i
        :title="$t('knowledge.QuestionOverview')"
        class="iconfont icon-wentizonglan1 hover-icon"
        style="
          font-size: 28px;
          vertical-align: middle;
          cursor: pointer;
          margin-right: 20px;
        "
        @click="showQuestion"
      />
    </el-badge>
    <i
      v-if="!converted && authList.indexOf('EDIT') !== -1"
      class="iconfont icon-siweidaotu1 hover-icon"
      style="
        font-size: 28px;
        width: fit-content;
        height: fit-content;
        vertical-align: middle;
        cursor: pointer;
        margin-right: 20px;
      "
      @click="turntoMind"
    />
    <el-dropdown
      v-else-if="converted && authList.indexOf('EDIT') !== -1"
      @command="handleCommand"
    >
      <span class="el-dropdown-link">
        <i
          class="iconfont icon-siweidaotu1"
          style="font-size: 28px; vertical-align: middle; margin-right: 20px"
        />
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="1" :disabled="!is_show_node"
          ><span
            :style="{
              color: is_show_node ? 'rgba(48, 100, 143, 1)' : '#968c8c',
            }"
            >{{ $t("knowledge.ViewMindMap") }}
            {{ is_show_node ? "" : "(" + $t("knowledge.Deleted") + ")" }}</span
          ></el-dropdown-item
        >
        <el-dropdown-item command="2"
          ><span style="color: rgba(48, 100, 143, 1)">{{
            $t("knowledge.CreateMindMap")
          }}</span></el-dropdown-item
        >
      </el-dropdown-menu>
    </el-dropdown>
    <i
      class="iconfont hover-icon"
      :title="
        collectionClass === 'icon-yishoucang'
          ? $t('knowledge.Unfavorite')
          : $t('knowledge.Favorite')
      "
      :class="collectionClass"
      style="
        font-size: 28px;
        vertical-align: middle;
        cursor: pointer;
        margin-right: 20px;
      "
      :style="{
        color:
          collectionClass === 'icon-weishoucang2' ? 'rgb(255, 195, 0)' : '',
      }"
      @click="collection"
    />
    <i
      :title="$t('knowledge.PermissionSetting')"
      class="iconfont hover-icon"
      :class="'icon-' + userClass"
      style="
        width: fit-content;
        height: fit-content;
        vertical-align: middle;
        margin-right: 20px;
        cursor: pointer;
        font-size: 28px;
      "
      @click="userlockFn"
    />
    <el-button
      v-if="authList.indexOf('EDIT') !== -1 && open_tiptap"
      type="primary"
      style="margin-right: 10px"
      :disabled="!draggable"
      @click="editFn"
      >{{ $t("btn.editBtn") }}
    </el-button>
    <el-button
      type="primary"
      style="margin-left: 0px; margin-right: 10px"
      @click="quickSharingfn"
      >{{ $t("knowledge.QuickShare") }}
    </el-button>
    <el-dropdown :hide-on-click="false" trigger="click" @command="toolClick">
      <span
        style="
          color: rgba(48, 100, 143, 1);
          font-size: 20px;
          font-weight: bold;
          letter-spacing: -3px;
          cursor: pointer;
        "
      >
        ···
      </span>
      <el-dropdown-menu slot="dropdown">
        <!-- <el-dropdown-item>页面历史</el-dropdown-item> -->
        <el-dropdown-item
          v-if="jobAuthorities.indexOf('KNOWLEDGE_EXPORT') !== -1"
        >
          <el-dropdown trigger="click" style="width: 100%" @command="exportFn">
            <div style="width: 100%">{{ $t("btn.exportBtn") }}</div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="word">word</el-dropdown-item>
              <el-dropdown-item command="pdf">pdf</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-dropdown-item>
        <el-dropdown-item
          :disabled="!authorityForm.interView"
          command="copyInternet"
          >{{ $t("knowledge.CopyExternalLink") }}
        </el-dropdown-item>
        <el-dropdown-item @click.native="followDoc()">
          {{ isFollowed ? $t("knowledge.Unfollow") : $t("knowledge.Follow") }}
        </el-dropdown-item>
        <el-dropdown-item @click.native="goToHistoryPage()">
          {{ $t("knowledge.ViewHistoricalVersions") }}
        </el-dropdown-item>
        <el-dropdown-item @click.native="addToConfigurationManagement()">
          {{ $t("configurationManagement.addToConfigurationManagement") }}
        </el-dropdown-item>
        <el-dropdown-item
          v-if="userAccountId == authorityForm.createdBy"
          command="transfer"
          >{{ $t("knowledge.TransferOwnership") }}
        </el-dropdown-item>
        <el-dropdown-item @click.native="openSaveTemplateDialog"
          >{{ $t("knowledge.saveAsTemplate") }}
        </el-dropdown-item>
        <el-dropdown-item :disabled="!draggable" @click.native="openMoveDialog">
          {{ $t("btn.movebtn") }}
        </el-dropdown-item>
        <el-dropdown-item @click.native="copyFormDialog = true">
          {{ $t("btn.copyBtn") }}
        </el-dropdown-item>
        <el-dropdown-item
          v-if="authList.indexOf('EDIT') !== -1"
          :disabled="!draggable"
          command="delete"
          >{{ $t("btn.deleteBtn") }}
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <el-dialog
      :title="turntoMindDialogTitle"
      :visible.sync="turntoMindDialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="true"
      width="25%"
    >
      <div v-if="!checkedFileType">
        <el-select
          v-model="fileTypeValue"
          :placeholder="$t('placeholder.dropdown')"
          filterable
          style="width: 100%"
        >
          <el-option
            v-for="item in fileTypeList"
            :key="item.fileTypeId"
            :label="item.name"
            :value="item.fileTypeId"
          >
          </el-option>
        </el-select>
      </div>
      <div v-else class="loading_wrap">
        <div>
          <div>
            <i
              class="iconfont"
              :class="'icon-' + stepIcon"
              :style="{ color: stepIconColor }"
              style="font-size: 33px"
            ></i>
          </div>
          <div>
            <!--            <p>{{ setpTitle }}</p>-->
            <p style="color: rgba(56, 56, 56, 1); font-size: 14px">
              {{ setpContext }}
            </p>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button v-if="turnStep !== 2" @click="turntoMindDialogCancel">{{
          stepCancelButton
        }}</el-button>
        <el-button
          v-if="turnStep !== 2"
          type="primary"
          @click="turntoMindDialogConfrim"
          >{{ stepConfrimButton }}</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="chooseWaydialogVisible"
      :close-on-click-modal="false"
      :show-close="true"
      class="upexcel"
      width="25%"
    >
      <div slot="title" style="color: #30648f; margin: 0; font-size: 1.3em">
        {{ $t("homeTopBar.newFileForm.excel.title") }}
      </div>
      <div v-if="is_show_node" style="text-align: left">
        <p style="font-weight: bold; color: #30648f">
          {{ $t("knowledge.OverwriteMindMap") }}：
        </p>
        <p>1.{{ $t("knowledge.DeleteExceptRoot") }}</p>
        <p>2.{{ $t("knowledge.LinkArticleHeadings") }}</p>
        <p style="font-weight: bold; color: #30648f">
          {{ $t("knowledge.PreserveOriginalMap") }}：
        </p>
        <p>1.{{ $t("knowledge.PreserveRemoveLink") }}</p>
        <p>2.{{ $t("knowledge.LinkNewMindMap") }}</p>
      </div>
      <div v-else style="text-align: left">
        <p style="font-weight: bold; color: #30648f">
          {{ $t("knowledge.RegenerateMindMap") }}：
        </p>
        <p>1.{{ $t("knowledge.regenerateNewOne") }}</p>
        <p>2.{{ $t("knowledge.LinkNewMindMap") }}</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button v-if="is_show_node" type="danger" @click="chooseWayCover">{{
          $t("knowledge.OverwriteMindMap")
        }}</el-button>
        <el-button v-if="is_show_node" type="primary" @click="chooseWayNew">{{
          $t("knowledge.PreserveOriginalMap")
        }}</el-button>
        <el-button v-else type="primary" @click="chooseWayNew"
          >重新生成思维导图</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="quickSharingVisible"
      width="25%"
      :close-on-click-modal="false"
      :show-close="true"
    >
      <div slot="title">
        {{ $t("knowledge.QuickShare") }}
        <el-popover
          placement="top-start"
          width="250"
          trigger="hover"
          :content="$t('knowledge.ShareWithinTenant')"
        >
          <i
            slot="reference"
            class="iconfont icon-help"
            style="margin-left: 5px; transform: scale(1.2)"
          ></i>
        </el-popover>
      </div>
      <div>
        <el-form
          ref="quickSharingForm"
          :model="quickSharingForm"
          label-width="120px"
          label-position="left"
        >
          <el-form-item prop="userList" :label="$t('knowledge.ShareWithUsers')">
            <el-select
              v-model="quickSharingForm.userList"
              multiple
              filterable
              :placeholder="$t('placeholder.dropdown')"
              style="width: 100%"
            >
              <el-option-group
                v-for="(group, index) in groupandUser"
                :key="group.label"
                :label="group.label"
              >
                <el-option
                  v-for="user in group.options"
                  :key="user.value"
                  :label="user.label"
                  :value="user.value + index"
                >
                </el-option>
              </el-option-group>
              <!-- <el-option
                v-for="item in allUserList"
                v-show="!item.deleted && item.accountId !== userAccountId"
                :key="item.accountId"
                :label="item.nickname"
                :value="item.accountId"
              >
              </el-option> -->
            </el-select>
          </el-form-item>
        </el-form>
        <p style="color: rgba(166, 166, 166, 1); margin-bottom: 0">
          {{ $t("knowledge.QuickShareNote") }}
        </p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="info" @click="quickSharingCancel">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button type="primary" @click="quickSharingConfrim">{{
          $t("knowledge.ConfirmSharing")
        }}</el-button>
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="authorityVisible"
      width="25%"
      :close-on-click-modal="false"
      :show-close="true"
    >
      <div slot="title">{{ $t("knowledge.Permissions") }}</div>
      <div style="text-align: left">
        <el-form
          ref="authorityForm"
          :model="authorityForm"
          label-width="120px"
          label-position="left"
        >
          <el-form-item prop="only">
            <span
              slot="label"
              style="line-height: 20px; display: inline-block"
              >{{ $t("knowledge.PageOwner") }}</span
            >
            <span style="color: #30648f">{{
              matchUserNickname(authorityForm.createdBy, "")
            }}</span>
          </el-form-item>
        </el-form>
        <el-form
          ref="authorityForm"
          :model="authorityForm"
          label-width="120px"
          label-position="left"
        >
          <el-form-item prop="only">
            <span slot="label">{{ $t("knowledge.Private") }} </span>
            <el-switch
              v-model="authorityForm.only"
              :disabled="authList.indexOf('SETTING') === -1"
            ></el-switch>
          </el-form-item>
        </el-form>
        <el-form
          v-if="!authorityForm.only"
          ref="authorityForm"
          :model="authorityForm"
          label-width="120px"
          label-position="left"
        >
          <el-form-item prop="only">
            <span
              slot="label"
              style="line-height: 20px; display: inline-block"
              >{{ $t("knowledge.InternallyPublic") }}</span
            >
            <el-switch
              v-model="authorityForm.public"
              :disabled="authList.indexOf('SETTING') === -1"
            ></el-switch>
          </el-form-item>
        </el-form>
        <p v-if="!authorityForm.only && !authorityForm.public">
          {{ $t("knowledge.InternalPermissionSettings") }}
        </p>
        <div v-if="!authorityForm.only && !authorityForm.public">
          <p
            v-for="(item, index) in authorityForm.authorityList"
            :key="index"
            class="authority-list"
          >
            <span>
              <el-select
                v-model="item.type"
                :placeholder="$t('placeholder.dropdown')"
                filterable
                style="width: 100%"
                :disabled="authList.indexOf('SETTING') === -1"
              >
                <el-option
                  v-for="authoritySelet in authoritySeletList"
                  v-show="authoritySelet.show"
                  :key="authoritySelet.value"
                  :label="authoritySelet.label"
                  :value="authoritySelet.value"
                >
                </el-option>
              </el-select>
            </span>
            <span>
              <el-select
                v-model="item.userList"
                multiple
                filterable
                :placeholder="$t('placeholder.dropdown')"
                style="width: 100%"
                :disabled="authList.indexOf('SETTING') === -1"
              >
                <el-option-group
                  v-for="(group, index) in groupandUser"
                  :key="group.label"
                  :label="group.label"
                >
                  <el-option
                    v-for="user in group.options"
                    :key="user.value"
                    :label="user.label"
                    :value="user.value + index"
                  >
                  </el-option>
                </el-option-group>
              </el-select>
            </span>
            <span
              v-if="authList.indexOf('SETTING') !== -1"
              @click="deleteAuthority(index)"
            >
              <img src="@/assets/img/narrow.png" alt="" />
            </span>
          </p>
        </div>
        <p
          v-show="
            !authorityForm.only &&
            !authorityForm.public &&
            authList.indexOf('SETTING') !== -1
          "
        >
          <el-button
            type="info"
            icon="el-icon-plus"
            :disabled="
              authorityForm.authorityList.length >= authoritySeletList.length
            "
            @click="addAuthority"
            >{{ $t("knowledge.Add") }}
          </el-button>
          <span
            v-if="
              !authorityForm.only &&
              !authorityForm.public &&
              (!authorityForm.authorityList.length ||
                (authorityForm.authorityList[0] &&
                  !authorityForm.authorityList[0].userList.length) ||
                !authorityForm.authorityList[0].type)
            "
            style="color: #f56c6c; margin-left: 12px"
            >{{ $t("knowledge.SelectAtLeastOneUser") }}</span
          >
        </p>
        <p v-if="!authorityForm.only">
          {{ $t("knowledge.InternetPermissionSettings") }}
        </p>
        <p v-if="!authorityForm.only">
          <el-checkbox
            v-model="authorityForm.interView"
            :label="true"
            :disabled="authList.indexOf('SETTING') === -1"
            @change="editInterView"
            >{{ $t("knowledge.InternetUsersCanView") }}
          </el-checkbox>
          <el-checkbox
            v-model="authorityForm.interEdit"
            :label="true"
            :disabled="authList.indexOf('SETTING') === -1"
            @change="editInterEdit"
            >{{ $t("knowledge.InternetUsersCanEdit") }}
          </el-checkbox>
        </p>
        <el-form
          ref="authorityForm"
          :model="authorityForm"
          label-width="200px"
          label-position="left"
        >
          <el-form-item prop="only">
            <span
              slot="label"
              style="line-height: 20px; display: inline-block"
              >{{ $t("knowledge.SyncSubpagePermissions") }}</span
            >
            <el-switch
              v-model="authorityForm.sync"
              :disabled="authList.indexOf('SETTING') === -1"
            ></el-switch>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="info" @click="authorityCancel">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button
          type="primary"
          :disabled="
            !authorityForm.only &&
            !authorityForm.public &&
            (!authorityForm.authorityList.length ||
              (authorityForm.authorityList[0] &&
                !authorityForm.authorityList[0].userList.length) ||
              !authorityForm.authorityList[0].type)
          "
          @click="authorityConfrim"
          >{{ $t("btn.confirmBtn") }}</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="internetLinkVisible"
      width="25%"
      :close-on-click-modal="false"
      :show-close="true"
    >
      <div slot="title">{{ $t("knowledge.ExternalLink") }}</div>
      <div class="internetLink">
        <div>
          URL:
          <div class="internetLink__bg">{{ internetLink1 }}</div>
        </div>
        <div v-show="internetLink2" style="margin-top: 10px">
          Token:
          <div class="internetLink__bg">{{ internetLink2 }}</div>
        </div>
        <el-button
          style="margin-top: 10px"
          type="primary"
          @click="copyInternetLink"
          >{{ $t("knowledge.Copy") }}</el-button
        >
        <label style="display: block">
          <textarea ref="text_area" style="opacity: 0"></textarea>
        </label>
      </div>
    </el-dialog>
    <!-- 转移所有权弹窗 -->
    <el-dialog
      :visible.sync="transferVisible"
      width="25%"
      :close-on-click-modal="false"
      :show-close="true"
    >
      <div slot="title">{{ $t("knowledge.TransferOwnership") }}</div>
      <div>
        <el-form
          ref="transferForm"
          :model="transferForm"
          label-width="120px"
          label-position="left"
        >
          <el-form-item
            prop="newAccountId"
            :label="$t('knowledge.SelectUsers')"
          >
            <el-select
              v-model="transferForm.newAccountId"
              :placeholder="$t('placeholder.dropdown')"
              filterable
              style="width: 100%"
            >
              <el-option
                v-for="item in PUserList"
                v-show="!item.deleted"
                :key="item.accountId"
                :label="item.nickname"
                :value="item.accountId"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="info" @click="transferCancel">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button type="primary" @click="transferConfrim">{{
          $t("knowledge.ConfirmTransfer")
        }}</el-button>
      </span>
    </el-dialog>
    <!-- 移动弹窗 -->
    <el-dialog
      :visible.sync="moveDialogVisible"
      :close-on-click-modal="false"
      :show-close="true"
      width="25%"
      :title="$t('knowledge.MoveAcrossProjectPages')"
      @close="resetForm('moveForm')"
    >
      <el-form
        ref="moveForm"
        :model="moveForm"
        :rules="moveRules"
        label-width="120px"
        label-position="left"
      >
        <el-form-item
          :label="$t('knowledge.TargetProject')"
          prop="targetProject"
        >
          <el-select
            v-model="moveForm.targetProject"
            :placeholder="$t('placeholder.dropdown')"
            filterable
            style="width: 100%"
            @change="getTargetDocument"
          >
            <el-option
              v-for="(column, index) in filteredProgramNameList"
              v-show="column.projectId !== get_pid()"
              :key="column.projectId"
              :label="column.name"
              :value="column.projectId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('knowledge.TargetParentPage')">
          <el-select
            v-model="moveForm.fatherDocument"
            :placeholder="$t('placeholder.dropdown')"
            filterable
            style="width: 100%"
          >
            <el-option :label="$t('knowledge.RootDirectory')" value="">
            </el-option>
            <el-option
              v-for="(column, index) in filteredTargetParentList"
              v-show="column.authList.length > 0"
              :key="column.documentId"
              :label="column.label"
              :value="column.documentId"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <div style="width: 100%; text-align: left">
          <el-checkbox
            v-model="moveForm.keepSource"
            :label="$t('knowledge.KeepOriginalPage')"
          >
          </el-checkbox>
        </div>
        <div style="width: 100%; text-align: left; margin-top: 10px">
          <el-checkbox
            v-model="moveForm.includeChildren"
            :label="$t('knowledge.includeChildren')"
          >
          </el-checkbox>
        </div>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="closeMoveDialog">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button type="primary" @click="submitMoveForm('moveForm')">{{
          $t("btn.confirmBtn")
        }}</el-button>
      </div>
    </el-dialog>
    <!-- 复制弹窗 -->
    <el-dialog
      :visible.sync="copyFormDialog"
      :close-on-click-modal="false"
      :show-close="true"
      width="25%"
      :title="$t('knowledge.SameProjectPageCopy')"
      @close="resetForm('copyForm')"
    >
      <el-form
        ref="copyForm"
        :model="copyForm"
        label-width="120px"
        label-position="left"
      >
        <el-form-item :label="$t('knowledge.TargetParentPage')">
          <el-select
            v-model="copyForm.targetParentId"
            :placeholder="$t('knowledge.PleaseSelect')"
            filterable
            style="width: 100%"
          >
            <el-option :label="$t('knowledge.RootDirectory')" value="">
            </el-option>
            <el-option
              v-for="(column, index) in filterCopyParentList"
              v-show="column.authList.length > 0"
              :key="column.documentId"
              :label="column.label"
              :value="column.documentId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <div style="width: 100%; text-align: left">
          <el-checkbox
            v-model="copyForm.includeChildren"
            :label="$t('knowledge.includeChildren2')"
          >
          </el-checkbox>
        </div>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="closeCopyDialog">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button type="primary" @click="submitCopyForm">{{
          $t("btn.confirmBtn")
        }}</el-button>
      </div>
    </el-dialog>
    <!-- 另存为模板弹窗 -->
    <el-dialog
      :visible.sync="templateFormDialog"
      :close-on-click-modal="false"
      :show-close="true"
      width="25%"
      :title="$t('knowledge.saveAsTemplate')"
      @close="resetForm('saveTemplateForm')"
    >
      <el-form
        ref="saveTemplateForm"
        :rules="saveTemplateRules"
        :model="saveTemplateForm"
        label-width="110px"
        label-position="left"
      >
        <el-form-item :label="$t('gantt.Name')" prop="name">
          <el-input v-model="saveTemplateForm.name"></el-input>
        </el-form-item>
        <el-form-item :label="$t('gantt.Desc')" prop="desc">
          <el-input v-model="saveTemplateForm.desc"></el-input>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="closeTemplateDialog('saveTemplateForm')">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button
          type="primary"
          @click="submitTemplateForm('saveTemplateForm')"
          >{{ $t("btn.confirmBtn") }}</el-button
        >
      </div>
    </el-dialog>
    <el-drawer
      :title="$t('knowledge.QuestionOverview')"
      :visible.sync="questionDrawer"
      size="40%"
      style="text-align: left"
    >
      <div class="question-list">
        <div v-for="(item, index) in questionList" :key="index">
          <div>
            <div>{{ index + 1 }}</div>
            <div v-html="item.content"></div>
          </div>
          <div>
            <div v-if="item.phoneNumber">
              <i class="iconfont icon-shouji" />
              {{ item.phoneNumber }}
            </div>
            <div v-if="item.email">
              <i class="iconfont icon-a-youxiang1" />
              {{ item.email }}
            </div>
            <div style="display: flex; justify-content: right; flex-grow: 1">
              <el-select
                v-model="item.status"
                :class="{
                  status: true,
                  status_small: true,
                  status_todo: item.status == 'TODO',
                  status_progress: item.status == 'PROCESSING',
                  status_done: item.status == 'DONE',
                  status_abandon: item.status == 'ABANDON',
                }"
                placeholder=" "
                @change="changeWorkflow($event, item)"
              >
                <el-option
                  v-for="opt in questionOptions"
                  :key="opt.value"
                  :label="opt.label"
                  :value="opt.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
      </div>
    </el-drawer>
    <a id="downimg" href="" style="display: none"></a>
    <addToConfig
      :dialog-show="addToConfigDialog"
      :document-id="checkedNode.documentId"
      @hideAddToConfigDialog="addToConfigDialog = false"
    ></addToConfig>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import { get_group_user_list } from "@/network/addGroup/index.js";
import {
  deleteDocument,
  ifConverted,
  convert,
  distribution,
  cover,
  newFile,
  collections,
  deleteCollections,
  collected,
  getSetting,
  editSetting,
  checkFather,
  ownership,
  quickShare,
  internetShare,
  getQuestions,
  changeQuestionsStatuses,
  wordFile,
  pdfFile,
  copyDocument,
  getAuthProjects,
  getDocuments,
  copyDocumentInternal,
  getAuth,
  followDoc,
  unfollowDoc,
  isFollowed,
  getAllFollowed,
  exitEdit,
} from "@/network/knowledge";
import { get_file_exist } from "@/network/review/index.js";
import { request_allPageProjects } from "@/network/personal/index.js";
import saveAs from "file-saver";
import htmlDocx from "html-docx-js/dist/html-docx";
import { downloadLink } from "@/utils/download.js";
import html2Canvas from "html2canvas";
import JsPDF from "jspdf";
import { ulid } from "ulid";
import { ossAuthorization } from "@/utils/oss";
import { async } from "@antv/x6/lib/registry/marker/async";
import { createTemplate } from "@/network/knowledgeTemplate/index.js";
import addToConfig from "@/views/configurationManagement/components/addToConfig.vue";
import { uploadES, getContent } from "@/network/knowledge";

export default {
  inject: ["menuList"],
  components: {
    addToConfig,
  },
  props: {
    checkedNode: {
      type: Object,
      default() {
        return null;
      },
    },
    authList: {
      type: Array,
      default() {
        return [];
      },
    },
    catalogueData: {
      type: Array,
      default() {
        return [];
      },
    },
    titleMindData: {
      type: Array,
      default() {
        return [];
      },
    },
    draggable: {
      type: Boolean,
      default: true,
    },
    html: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      is_zdh: false,
      open_tiptap: false,
      addToConfigDialog: false,
      isFollowed: false,
      collectionClass: "icon-weishoucang",
      turntoMindDialog: false,
      turntoMindDialogTitle: this.$t("knowledge.SelectFileType"),
      fileTypeValue: "",
      checkedFileType: false,
      turnStep: "1",
      setpTitle: "",
      setpContext: "",
      stepCancelButton: this.$t("btn.cancelBtn"),
      stepConfrimButton: this.$t("btn.confirmBtn"),
      stepIcon: "alert_blue",
      stepIconColor: "#e4a13c",
      groupandUser: [
        {
          label: this.$t("knowledge.user"),
          options: [],
        },
        {
          label: this.$t("knowledge.userGroup"),
          options: [],
        },
      ],
      mindData: {},
      // 是否已经转换为思维导图
      converted: false,
      covertAgain: false,
      is_show_node: false,
      // 新建思维导图分支弹窗
      chooseWaydialogVisible: false,
      // 快捷分享弹窗
      quickSharingVisible: false,
      quickSharingForm: {
        userList: [],
      },
      transferForm: {
        newAccountId: "",
      },
      //权限设置相关
      authorityVisible: false,
      authorityForm: {
        only: false,
        public: false,
        sync: false,
        authorityList: [],
        interView: false,
        interEdit: false,
        createdBy: "",
      },
      settingId: "",
      //外部链接弹窗
      internetLinkCopy: "",
      internetLink1: "",
      internetLink2: "",
      internetLinkVisible: false,
      transferVisible: false,
      questionDrawer: false,
      questionList: [],
      questionOptions: [
        {
          value: "TODO",
          label: this.$t("canvas.status.st1"),
        },
        {
          value: "PROCESSING",
          label: this.$t("canvas.status.st2"),
        },
        {
          value: "DONE",
          label: this.$t("canvas.status.st3"),
        },
        {
          value: "ABANDON",
          label: this.$t("knowledge.Abandoned"),
        },
      ],
      moveDialogVisible: false,
      moveForm: {
        targetProject: "",
        fatherDocument: "",
        keepSource: true,
        includeChildren: false,
      },
      moveRules: {
        targetProject: [
          {
            required: true,
            message: this.$t("knowledge.TargetItemCannotBeEmpty"),
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              if (value == "" || value == null || value == undefined) {
                callback(
                  new Error(this.$t("knowledge.TargetItemCannotBeEmpty"))
                );
              } else {
                callback();
              }
            },
            trigger: "change",
          },
        ],
      },
      program_name: "", //头部显示项目名称
      program_name_list: [], //项目下拉列表
      avatar_p: [],
      tenantid_list: [],
      id_list: [],
      type_list: [],
      targetParentList: [],
      filter_id_list: [],
      copyForm: {
        targetParentId: "",
        includeChildren: false,
      },
      copyFormDialog: false,
      documentList: this.menuList(),
      // 另存为模板
      templateFormDialog: false,
      saveTemplateForm: {
        name: "",
        desc: "",
      },
      saveTemplateRules: {
        name: [
          {
            required: true,
            message: this.$t("placeholder.name"),
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters([
      "fileType",
      "user_list",
      "project_user_list",
      "userAccountId",
      "jobAuthorities",
      "user_group_list",
    ]),
    menuList() {
      return this.menuList();
    },
    questionNumber() {
      let num = 0;
      this.questionList.forEach((item) => {
        if (item.status === "TODO") {
          num++;
        }
      });
      return num;
    },
    userClass() {
      let className = "gongkaiquanxian";
      if (this.authorityForm.authorityList.length !== 0) {
        className = "gongkaiquanxian";
      }
      if (this.authorityForm.interView) {
        className = "hulianwangquanxian";
      }
      if (this.authorityForm.only) {
        className = "simiquanxian";
      }
      return className;
    },
    fileTypeList() {
      return Object.values(this.fileType);
    },
    PUserList() {
      return Object.values(this.project_user_list);
    },
    authoritySeletList() {
      const arr = [
        {
          label: this.$t("btn.view"),
          value: "READ",
          show: true,
        },
        {
          label: this.$t("btn.editBtn"),
          value: "EDIT",
          show: true,
        },
      ];
      const filterArr = arr.filter((item) => {
        let flag = true;
        this.authorityForm.authorityList.forEach((item1, index1) => {
          if (item1.type === item.value) {
            item.show = false;
          }
        });
        return flag;
      });
      return filterArr;
    },
    filteredTargetParentList() {
      return this.targetParentList.filter(
        (column) => column.authList.length > 0
      );
    },
    filteredProgramNameList() {
      let self = this;
      return this.program_name_list
        .map((name, index) => ({ name, projectId: self.id_list[index] }))
        .filter(
          (item) =>
            item.id !== this.get_pid() &&
            this.filter_id_list.includes(item.projectId)
        );
    },
    filterCopyParentList() {
      return this.documentList.filter((column) => column.authList.length > 0);
    },
  },
  watch: {
    checkedNode(neval) {
      this.ifConverted();
      this.collected();
      this.getSetting();
    },
    titleMindData(neval) {
      this.get_file_exist();
    },
    "authorityForm.only"(newval) {
      if (newval) {
        this.authorityForm.interEdit = false;
        this.authorityForm.interView = false;
      }
    },
  },
  mounted() {
    document.addEventListener("keydown", (event) => {
      if (event.ctrlKey && event.altKey && event.key === "m") {
        this.is_zdh = true;
      }
    });

    localStorage.getItem("open_tiptap")
      ? ""
      : localStorage.setItem("open_tiptap", true);

    this.open_tiptap = JSON.parse(
      localStorage.getItem("open_tiptap")
        ? localStorage.getItem("open_tiptap")
        : "false"
    );
    this.setallGroup();
    // 检查思维导图转换结果
    this.ifConverted();
    this.get_file_exist();
    this.collected();
    this.getSetting();
    this.getFollowed();
    // 初始化获取questionList
    const params = {
      projectId: this.get_pid(),
      documentId: this.checkedNode.documentId,
    };
    getQuestions(params).then((res) => {
      this.questionList = res;
      this.questionList.forEach((item) => {
        item.content = item.content.replace(/\n/g, "<br>");
      });
    });

    setTimeout(() => {
      let tables = document.querySelectorAll("table");
      if (tables.length > 0) {
        this.fix_table();
      }
    }, 2000);
  },
  created() {
    this.getAllProgram();
  },
  methods: {
    ...mapMutations(["SET_KNOWLEDGE_EDIT"]),
    fix_table() {
      let flag = false;

      function trimTable(table, maxRows, maxCols) {

        // 删除多余的行
        while (table.rows.length > maxRows) {
          table.deleteRow(-1);
          flag = true;
        }

        // 遍历每一行，删除多余的列
        for (let i = 0; i < table.rows.length; i++) {
          const row = table.rows[i];
          while (row.cells.length > maxCols) {
            row.deleteCell(-1);
            flag = true;
          }
        }
      }
      const params = {
        projectId: this.get_pid(),
        documentId: this.checkedNode.documentId,
      };

      getContent(params).then((res) => {
        let max_hang = 0;
        let max_lie = 0;

        const contain = document.createElement("div");
        contain.innerHTML = res.contentHtml;
        const tables = contain.querySelectorAll("table");
        tables.forEach((table) => {
          for (let i = 0; i < table.rows.length; i++) {
            const row = table.rows[i];
            // 遍历当前行的每一列
            for (let j = 0; j < row.cells.length; j++) {
              const cell = row.cells[j];
              if (cell.textContent) {
                if (i > max_hang) {
                  max_hang = i;
                }

                if (j > max_lie) {
                  max_lie = j;
                }
              }
            }
          }
          max_hang++;
          max_lie++;
          trimTable(table, max_hang, max_lie);
        });

        if (flag == true) {
          alert("表格有问题，已更新，请将本条信息发给zdh")
          console.log("上传");

          let da = {
            contentHtml: contain.innerHTML,
            contentJson: "",
            contentBase64: "",
            updateBase64 :true
          };
          uploadES(
            this.$route.params.projectId,
            this.$route.query.documentId,
            da
          );
        }

        // const table = document.getElementById("myTable");
        // trimTable(table, maxRows, maxCols);
      });
    },
    goto_tiptap(d) {
      localStorage.setItem("open_tiptap", d);
      this.$router.go(0);
    },
    arrayDeduplication(arr1, arr2) {
      const mergedArray = arr1.concat(arr2);
      const uniqueArray = [...new Set(mergedArray)];
      return uniqueArray;
    },
    setNamelist(arr) {
      let list = this.$store.state.store_tenant.user;
      let returnlist = [];
      for (const key in list) {
        arr.map((item) => {
          if (key == item) {
            returnlist.push(list[key].nickname);
          }
        });
      }
      return returnlist;
    },
    setallGroup() {
      const group = Object.values(this.user_group_list);
      const user = Object.values(this.user_list);
      const filteredArray = user.filter((obj) => obj.deleted == 0);
      filteredArray.map((item, index) => {
        this.groupandUser[0].options.push({
          value: item.accountId,
          label: item.nickname,
        });
      });
      group.map((item, index) => {
        this.groupandUser[1].options.push({
          value: item.groupId,
          label: item.groupName,
        });
      });
    },
    getFollowed() {
      const params = {
        projectId: this.get_pid(),
        documentId: this.checkedNode.documentId,
      };
      isFollowed(params).then((res) => {
        this.isFollowed = res;
      });
    },
    followDoc() {
      if (!this.isFollowed) {
        const params = {
          projectId: this.get_pid(),
          documentId: this.checkedNode.documentId,
        };
        followDoc(params).then((res) => {
          isFollowed(params).then((res) => {
            this.isFollowed = res;
          });
        });
      } else {
        const params = {
          projectId: this.get_pid(),
          documentId: this.checkedNode.documentId,
        };
        unfollowDoc(params).then((res) => {
          isFollowed(params).then((res) => {
            this.isFollowed = res;
          });
        });
      }
    },
    // 修改问题状态
    changeWorkflow(value, item) {
      const params = {
        projectId: this.get_pid(),
        documentId: this.checkedNode.documentId,
        questionId: item.questionId,
        query: {
          status: value,
        },
      };
      changeQuestionsStatuses(params).then((res) => {
        this.showQuestion();
      });
    },
    // 展示问题抽屉
    showQuestion() {
      const params = {
        projectId: this.get_pid(),
        documentId: this.checkedNode.documentId,
      };
      getQuestions(params).then((res) => {
        this.questionList = res;
        this.questionList.forEach((item) => {
          item.content = item.content.replace(/\n/g, "<br>");
        });
        this.questionDrawer = true;
      });
    },
    // 获取互联网分享链接
    internetShare() {
      const params = {
        projectId: this.get_pid(),
        documentId: this.checkedNode.documentId,
      };
      internetShare(params).then((res) => {
        const token = res.token;
        const url = res.url;
        this.internetLinkCopy = `${url}`;
        if (token !== "") {
          this.internetLinkCopy += ` Token:${token}`;
        }
        this.internetLink1 = url;
        this.internetLink2 = token;
      });
    },
    // 修改权限设置
    editSetting() {
      let data = {
        knowledgeAuthorities: [],
        readList: [],
        editList: [],
        readGroupList: [],
        editGroupList: [],
        syncChildrenDocs: this.authorityForm.sync,
      };
      this.authorityForm.authorityList.map((item, index) => {
        if (item.type == "READ") {
          item.userList.map((taga) => {
            if (taga.substr(-1) == 0) {
              data.readList.push(taga.substr(0, taga.length - 1));
            } else {
              data.readGroupList.push(taga.substr(0, taga.length - 1));
            }
          });
        } else {
          item.userList.map((tagb) => {
            if (tagb.substr(-1) == 0) {
              data.editList.push(tagb.substr(0, tagb.length - 1));
            } else {
              data.editGroupList.push(tagb.substr(0, tagb.length - 1));
            }
          });
        }
      });
      const map = {
        READ: "readList",
        EDIT: "editList",
      };
      this.authorityForm.only
        ? data.knowledgeAuthorities.push("OWNER_SEE_ONLY")
        : "";
      this.authorityForm.public ? data.knowledgeAuthorities.push("PUBLIC") : "";
      this.authorityForm.interView
        ? data.knowledgeAuthorities.push("INTERNET_READ")
        : "";
      this.authorityForm.interEdit
        ? data.knowledgeAuthorities.push("INTERNET_EDIT")
        : "";
      // this.authorityForm.authorityList.forEach((item) => {
      //   if (item.type) {
      //     data[map[item.type]] = item.userList;
      //   }
      // });
      const params = {
        projectId: this.get_pid(),
        settingId: this.settingId,
        data: data,
      };
      editSetting(params).then((res) => {
        this.$message({
          type: "success",
          message: this.$t("canvas.messages.editSuccess"),
        });
        this.authorityVisible = false;
        this.getSetting();
        if (data.knowledgeAuthorities.indexOf("INTERNET_READ") !== -1) {
          this.internetLinkVisible = true;
        }
        this.$emit("getAuth");
      });
    },
    // 检查父级权限
    checkFather() {
      const map = {
        READ: "readList",
        EDIT: "editList",
      };
      const data = {
        knowledgeAuthorities: [],
        readList: [],
        editList: [],
      };
      this.authorityForm.only
        ? data.knowledgeAuthorities.push("OWNER_SEE_ONLY")
        : "";
      this.authorityForm.public ? data.knowledgeAuthorities.push("PUBLIC") : "";
      this.authorityForm.interView
        ? data.knowledgeAuthorities.push("INTERNET_READ")
        : "";
      this.authorityForm.interEdit
        ? data.knowledgeAuthorities.push("INTERNET_EDIT")
        : "";
      this.authorityForm.authorityList.forEach((item) => {
        if (item.type) {
          data[map[item.type]] = item.userList;
        }
      });
      const params = {
        projectId: this.get_pid(),
        settingId: this.settingId,
        data: data,
      };
      return checkFather(params);
    },
    addPopList(arr, num) {
      let arr1 = [];
      if (arr.length != 0) {
        arr.map((item, index) => {
          arr1.push(item + num);
        });
      }
      return arr1;
    },
    // 获取权限设置
    getSetting() {
      if (!this.checkedNode) {
        return;
      }
      const params = {
        projectId: this.get_pid(),
        documentId: this.checkedNode.documentId,
      };
      getSetting(params).then((res) => {
        this.settingId = res.settingId;
        this.authorityForm = {
          only: false,
          public: false,
          sync: false,
          authorityList: [],
          interView: false,
          interEdit: false,
          createdBy: "",
        };
        this.authorityForm.createdBy = res.owner;
        this.$set(
          this.authorityForm,
          "only",
          res.knowledgeAuthorities.indexOf("OWNER_SEE_ONLY") !== -1
        );
        this.$set(
          this.authorityForm,
          "public",
          res.knowledgeAuthorities.indexOf("PUBLIC") !== -1
        );
        this.$set(
          this.authorityForm,
          "interView",
          res.knowledgeAuthorities.indexOf("INTERNET_READ") !== -1
        );
        //判断是否有互联网分享链接
        if (res.knowledgeAuthorities.indexOf("INTERNET_READ") !== -1) {
          this.internetShare();
        }
        this.$set(
          this.authorityForm,
          "interEdit",
          res.knowledgeAuthorities.indexOf("INTERNET_EDIT") !== -1
        );
        if (res.readList.length || res.readGroupList.length) {
          this.authorityForm.authorityList.push({
            type: "READ",
            userList: this.addPopList(res.readList, 0).concat(
              this.addPopList(res.readGroupList, 1)
            ),
          });
        }
        if (res.editList.length || res.editGroupList.length) {
          this.authorityForm.authorityList.push({
            type: "EDIT",
            userList: this.addPopList(res.editList, 0).concat(
              this.addPopList(res.editGroupList, 1)
            ),
          });
        }
      });
    },
    // 复制外部链接
    copyInternetLink() {
      let text = this.$refs["text_area"];
      text.innerText = this.$t("knowledge.CopyText") + this.internetLinkCopy;
      text.select();
      document.execCommand("copy");
      this.$message({
        type: "success",
        message: this.$t("knowledge.copied"),
      });
    },
    // 互联网可编辑选中/取消选中事件
    editInterView(val) {
      if (!val) {
        this.authorityForm.interEdit = val;
      }
    },
    editInterEdit(val) {
      if (val) {
        this.authorityForm.interView = val;
      }
    },
    // 添加一条权限
    addAuthority() {
      this.authorityForm.authorityList.push({
        type: "",
        userList: [],
      });
    },
    deleteAuthority(index) {
      this.authorityForm.authorityList.splice(index, 1);
    },
    // 权限设置相关
    userlockFn() {
      this.authorityVisible = true;
    },
    authorityCancel() {
      this.getSetting();
      this.authorityVisible = false;
    },
    async authorityConfrim() {
      const check = await this.checkFather();
      if (!check) {
        this.$confirm(
          this.$t("knowledge.PermissionInconsistencyWarning"),
          this.$t("review.tip11"),
          {
            confirmButtonText: this.$t("btn.confirmBtn"),
            cancelButtonText: this.$t("btn.cancelBtn"),
            type: "warning",
          }
        )
          .then(() => {
            this.editSetting();
          })
          .catch(() => {});
      } else {
        this.editSetting();
      }
    },
    // 快捷分享确认取消事件
    quickSharingfn() {
      this.quickSharingVisible = true;
    },
    async mergedUsergroup(arr, projectId) {
      let account = [];
      let group = [];
      arr.map((item, index) => {
        if (item.substr(-1) == 0) {
          account.push(item.substr(0, item.length - 1));
        } else {
          group.push(item.substr(0, item.length - 1));
        }
      });
      const params = {
        projectId: projectId,
        accountIdList: account,
        groupIdList: group,
      };
      return await get_group_user_list(params);
    },
    async quickSharingConfrim() {
      if (!this.quickSharingForm.userList.length) {
        this.$message({
          type: "warning",
          message: this.$t("knowledge.SelectTargetUser"),
        });
        return;
      }
      const melist = await this.mergedUsergroup(
        this.quickSharingForm.userList,
        ""
      );
      const params2 = {
        projectId: this.get_pid(),
        documentId: this.checkedNode.documentId,
        data: melist,
      };
      quickShare(params2).then((res) => {
        this.$message({
          message: this.$t("knowledge.SharingSucceeded"),
          type: "success",
        });
        this.quickSharingVisible = false;
      });
    },
    quickSharingCancel() {
      this.quickSharingVisible = false;
    },
    // 转移所有权确认弹窗
    transferConfrim() {
      if (!this.transferForm.newAccountId) {
        this.$message({
          type: "warning",
          message: this.$t("knowledge.SelectTargetUser"),
        });
        return;
      }
      const params = {
        projectId: this.get_pid(),
        documentId: this.checkedNode.documentId,
        newAccountId: this.transferForm.newAccountId,
      };
      ownership(params).then((res) => {
        this.$message({
          type: "success",
          message: this.$t("knowledge.TransferSuccessful"),
        });
        this.getSetting();
        this.$emit("getAuth");
        this.transferVisible = false;
      });
    },
    transferCancel() {
      this.transferVisible = false;
    },
    // 检查是否被收藏
    collected() {
      const params = {
        projectId: this.get_pid(),
        documentId: this.checkedNode.documentId,
      };
      collected(params).then((res) => {
        this.collectionClass = res ? "icon-weishoucang2" : "icon-weishoucang";
      });
    },
    // 检测关联思维导图是否还存在
    async get_file_exist() {
      if (!this.titleMindData[0]) {
        this.is_show_node = false;
        return;
      } else {
        this.is_show_node = await get_file_exist(
          this.get_pid(),
          this.titleMindData[0].fileKey
        );
      }
    },
    //检查思维导图转换结果
    ifConverted() {
      const params = {
        projectId: this.get_pid(),
        documentId: this.checkedNode.documentId,
      };
      ifConverted(params).then((res) => {
        this.converted = res;
      });
    },
    // 刷新或者新建思维导图
    handleCommand(cm) {
      if (cm == "1") {
        const { href } = this.$router.resolve({
          path: `/${this.get_pid()}/myMind/${this.titleMindData[0].fileKey}`,
        });
        window.open(href, "_blank");
      } else if (cm == "2") {
        // 新建思维导图
        this.chooseWaydialogVisible = true;
      }
    },
    chooseWayCover() {
      this.$confirm(
        "<span style='color: #f56c6c'>" +
          this.$t("knowledge.ConfirmOverwrite") +
          "</span>",
        this.$t("knowledge.DeleteNodesWarning"),
        {
          dangerouslyUseHTMLString: true,
          confirmButtonText: this.$t("btn.cancelBtn"),
          cancelButtonText: this.$t("knowledge.Overwrite"),
          cancelButtonClass: "button-danger",
          type: "warning",
        }
      )
        .then(() => {
          this.$message({
            type: "info",
            message: this.$t("knowledge.CancelOverwrite"),
          });
        })
        .catch(() => {
          const params = {
            projectId: this.get_pid(),
            documentId: this.checkedNode.documentId,
            data: this.catalogueData,
          };
          cover(params)
            .then((res) => {
              this.$message({
                type: "success",
                message: this.$t("knowledge.RefreshSuccessful"),
              });
              this.chooseWaydialogVisible = false;
              this.$emit("updateMindData");
            })
            .catch((err) => {});
        });
    },
    chooseWayNew() {
      this.chooseWaydialogVisible = false;
      this.turntoMindDialog = true;
      this.covertAgain = true; //判断是新生成思维导图 还是多次新建思维导图
      this.checkedFileType = false;
      this.turnStep = "1";
      this.turntoMindDialogTitle = this.$t("knowledge.SelectFileType");
      this.fileTypeValue = "";
      this.stepCancelButton = this.$t("btn.cancelBtn");
      this.stepConfrimButton = this.$t("btn.confirmBtn");
    },
    // 意见转化思维导图
    turntoMind() {
      this.covertAgain = false; //判断是新生成思维导图 还是多次新建思维导图
      this.checkedFileType = false;
      this.turnStep = "1";
      this.turntoMindDialogTitle = this.$t("knowledge.SelectFileType");
      this.fileTypeValue = "";
      this.stepCancelButton = this.$t("btn.cancelBtn");
      this.stepConfrimButton = this.$t("btn.confirmBtn");
      this.turntoMindDialog = true;
    },
    turntoMindDialogCancel() {
      this.turntoMindDialog = false;
    },
    // 第三步成功后的处理
    step3Fn(res) {
      this.mindData = res;
      this.$emit("updateMindData");
      this.setpTitle = this.$t("knowledge.ConversionSuccessful");
      this.setpContext = this.$t("knowledge.GoToMapPage");
      this.stepCancelButton = this.$t("knowledge.ReturnToKnowledgeBase");
      this.stepConfrimButton = this.$t("knowledge.ViewMindMap");
      this.turnStep = "3";
      this.stepIcon = "approved";
      this.stepIconColor = "#004ba9";
      this.ifConverted();
    },
    turntoMindDialogConfrim() {
      if (!this.fileTypeValue) {
        this.$message({
          type: "warning",
          message: this.$t("knowledge.FileTypeCannotBeEmpty"),
        });
        return;
      }
      switch (this.turnStep) {
        case "1": {
          this.setpTitle = this.$t("review.tip11");
          this.setpContext = this.$t("knowledge.TransformToMindMap");
          this.stepCancelButton = this.$t("btn.cancelBtn");
          this.stepConfrimButton = this.$t("btn.confirmBtn");
          this.turnStep = "2";
          this.stepIcon = "alert_blue";
          this.stepIconColor = "#e4a13c";
          break;
        }
        case "2": {
          this.setpTitle = this.$t("knowledge.Loading");
          this.setpContext = this.$t("knowledge.TransformingIntoMindMap");
          this.stepCancelButton = this.$t("btn.cancelBtn");
          this.stepConfrimButton = this.$t("btn.confirmBtn");
          this.stepIcon = "clocks_blue";
          this.stepIconColor = "#004ba9";
          const params = {
            projectId: this.get_pid(),
            documentId: this.checkedNode.documentId,
            data: {
              fileTypeId: this.fileTypeValue,
              documentContent: this.catalogueData,
            },
          };
          if (!this.covertAgain) {
            convert(params).then((res) => {
              const params = {
                projectId: this.get_pid(),
                documentId: this.checkedNode.documentId,
              };
              distribution(params).then((res) => {
                this.step3Fn(res);
              });
            });
          } else {
            newFile(params).then((res) => {
              const params = {
                projectId: this.get_pid(),
                documentId: this.checkedNode.documentId,
              };
              distribution(params).then((res) => {
                this.step3Fn(res);
                this.is_show_node = true;
              });
            });
          }
          break;
        }
        case "3": {
          const fileKeyObj = this.mindData.filter((item) => {
            return item.fileKey;
          });
          const { href } = this.$router.resolve({
            path: `/${this.get_pid()}/myMind/${fileKeyObj[0].fileKey}`,
          });
          window.open(href, "_blank");
          this.turntoMindDialog = false;
          break;
        }
      }
      this.checkedFileType = true;
      this.turntoMindDialogTitle = this.$t("knowledge.OneClickConversion");
    },
    collection() {
      const params = {
        projectId: this.get_pid(),
        documentId: this.checkedNode.documentId,
      };
      collected(params).then((res) => {
        res
          ? deleteCollections(params).then((res) => {
              this.collectionClass = "icon-weishoucang";
              this.$message({
                message: this.$t("knowledge.Unfavorite"),
                type: "warning",
              });
            })
          : collections(params).then((res) => {
              this.collectionClass = "icon-weishoucang2";
              this.$message({
                message: this.$t("knowledge.Favorite"),
                type: "success",
              });
            });
      });
    },
    showCascader() {},
    editFn() {
      this.SET_KNOWLEDGE_EDIT({
        knowledge_edit: true,
        knowledge_edit_mode: "edit",
      });
    },
    //  导出pdf
    exportPdf(name) {
      const self = this;
      //获取页面标题，作为文件名称，也可以使用时间戳生成不重复的文件名使用
      const html = JSON.parse(JSON.stringify(this.html));
      const div = document.createElement("div");
      div.innerHTML = html;
      const imgElement = div.querySelectorAll("img");
      // 导出pdf操作
      function exportPDF() {
        html2Canvas(div, {
          allowTaint: false,
          useCORS: true,
          // width: div.offsetWidth,
          // height: div.offsetHeight,
          dpi: 1, //将分辨率提高到特定的DPI 提高四倍
          scale: 1, //按比例增加分辨率
        })
          .then(function (canvas) {
            let contentWidth = canvas.width;
            let contentHeight = canvas.height;
            let pageData = canvas.toDataURL();
            let pageSizeArr = [contentWidth + 80, contentHeight + 20];
            if (contentWidth > contentHeight) {
              pageSizeArr = [contentWidth + 80, 1.4 * contentWidth + 20];
            }
            let PDF = new JsPDF("p", "pt", pageSizeArr);
            PDF.addImage(pageData, "JPEG", 40, 20, contentWidth, contentHeight);
            //文件导出生成
            PDF.save(name + ".pdf");
            self.$message({
              type: "success",
              message: this.$t("knowledge.ExportSuccessful"),
              duration: "5000",
            });
            self.exportMessage.close();
            document.body.removeChild(div);
          })
          .catch((err) => {
            console.log(err, "error");
            document.body.removeChild(div);
          });
      }
      if (imgElement.length) {
        imgElement.forEach((img) => {
          img.setAttribute("crossorigin", "*");
          img.src = img.src + `?timestamp=${ulid()}`;
        });
        this.convertImagesToBase64(imgElement, () => {
          document.body.appendChild(div);
          exportPDF();
        });
      } else {
        document.body.appendChild(div);
        exportPDF();
      }
    },
    // 图片转为base64 解决大小问题
    convertImagesToBase64(regularImages, callback) {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      let count = 0;
      [].forEach.call(regularImages, function (imgElement, index) {
        let image2 = new Image();
        image2.setAttribute("crossorigin", "anonymous");
        image2.src = imgElement.src + `?timestamp=${ulid()}`;
        image2.onload = function () {
          const imageWidth = image2.width;
          const imageHeight = image2.height;
          let scaleX;
          if (imageWidth < 600) {
            scaleX = 1;
          } else {
            scaleX = 600 / imageWidth;
          }
          canvas.width = imageWidth * scaleX * 2;
          canvas.height = imageHeight * scaleX * 2;
          canvas.style.width = imageWidth * scaleX;
          canvas.style.height = imageHeight * scaleX;
          // get the top left position of the image
          ctx.drawImage(
            image2,
            0,
            0,
            imageWidth * scaleX,
            imageHeight * scaleX
          );
          // 通过toDataURL保存图片，格式是base64格式的。
          let imageUrl = canvas.toDataURL("image/png");
          imgElement.setAttribute("src", imageUrl);
          count++;
          if (count === regularImages.length) {
            callback();
          }
        };
      });
      canvas.remove();
    },
    // 导出
    exportFn(command) {
      const name = this.checkedNode.label.replace(" ", "");
      const html = JSON.parse(JSON.stringify(this.html));
      const qlSnowDiv = document.createElement("div");
      const qlEditorDiv = document.createElement("div");
      qlEditorDiv.classList.add("ql-editor");
      qlSnowDiv.classList.add("ql-snow");
      qlEditorDiv.innerHTML = html;
      qlSnowDiv.appendChild(qlEditorDiv);
      const page =
        '<!DOCTYPE html><html><head><meta charset="UTF-8"><link rel="stylesheet" type="text/css" href="https://automind-oss.ytdevops.com/quill.css"></head><body>' +
        qlSnowDiv.outerHTML +
        "</body></html>";
      if (command === "word") {
        const page =
          '<!DOCTYPE html><html><head><meta charset="UTF-8"></head><body>' +
          qlSnowDiv.outerHTML +
          "</body></html>";
        const params = {
          projectId: this.get_pid(),
          data: {
            title: name,
            contentHtml: page,
          },
        };
        wordFile(params).then((res) => {
          this.$message({
            showClose: true,
            message: this.$t("homeTopBar.asyncTask.message"),
            type: "success",
            duration: 5000,
          });
        });
      } else if (command === "pdf") {
        const params = {
          projectId: this.get_pid(),
          data: {
            title: name,
            contentHtml: page,
          },
        };
        pdfFile(params).then((res) => {
          this.$message({
            showClose: true,
            message: this.$t("homeTopBar.asyncTask.message"),
            type: "success",
            duration: 5000,
          });
        });
      }
    },
    toolClick(command) {
      if (command === "delete") {
        this.$confirm(
          this.$t("knowledge.ConfirmPageDeletion1") +
            (this.checkedNode.children.length
              ? this.$t("knowledge.ConfirmPageDeletion2")
              : "") +
            this.$t("knowledge.ConfirmPageDeletion3"),
          this.$t("review.tip11"),
          {
            confirmButtonText: this.$t("btn.confirmBtn"),
            confirmButtonClass: "delete_button_class",
            cancelButtonText: this.$t("btn.cancelBtn"),
            cancelButtonClass: "delete_cancel_class",
            type: "warning",
          }
        )
          .then(() => {
            const params = {
              projectId: this.get_pid(),
              documentId: this.checkedNode.documentId,
            };
            deleteDocument(params).then((res) => {
              this.$emit("deleteDoc");
              this.$message({
                type: "success",
                message: this.$t("knowledge.DeletingPageSucceeded"),
              });
              this.$router.push({ path: `/${this.get_pid()}/knowledge` });
              // .then(() => window.location.reload());
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: this.$t("knowledge.DeletionCanceled"),
            });
          });
      } else if (command === "transfer") {
        this.transferVisible = true;
        this.transferForm.newAccountId = "";
      } else if (command === "copyInternet") {
        this.internetLinkVisible = true;
      }
    },
    goToHistoryPage() {
      this.$router.push({
        path: `/${this.get_pid()}/knowledgeHistory?documentId=${
          this.$route.query.documentId
        }&title=${this.$route.query.title}`,
      });
    },
    addToConfigurationManagement() {
      this.addToConfigDialog = true;
    },
    openMoveDialog() {
      this.moveDialogVisible = true;
    },
    closeMoveDialog() {
      this.moveDialogVisible = false;
      this.moveForm = {
        targetProject: "",
        fatherDocument: "",
        keepSource: true,
        includeChildren: false,
      };
    },
    closeCopyDialog() {
      this.copyFormDialog = false;
      this.copyForm = {
        targetParentId: "",
        includeChildren: false,
      };
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    submitMoveForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (!this.moveForm.keepSource) {
            this.$confirm(
              this.$t("knowledge.MovingAPage"),
              this.$t("knowledge.wanning"),
              {
                confirmButtonText: this.$t("btn.confirmBtn"),
                cancelButtonText: this.$t("btn.cancelBtn"),
                type: "warning",
              }
            )
              .then(async () => {
                await this.moveDocument();
                this.$router
                  .push({ path: `/${this.get_pid()}/knowledge` })
                  .then(() => window.location.reload());
              })
              .catch((err) => {});
          } else {
            this.moveDocument();
          }
        } else {
          return false;
        }
      });
    },
    submitCopyForm() {
      const params = {
        projectId: this.get_pid(),
        documentId: this.checkedNode.documentId,
        data: {
          parentId: this.copyForm.targetParentId,
          includeChildren: this.copyForm.includeChildren,
        },
      };
      this.copyFormDialog = false;
      copyDocumentInternal(params).then((res) => {
        this.$message({
          type: "success",
          message: this.$t("knowledge.PageCopySucceeded"),
        });
        this.$router
          .push({
            path: `/${this.get_pid()}/knowledge?documentId=${
              this.checkedNode.documentId
            }&title=${this.checkedNode.label}`,
          })
          .then(() => window.location.reload());
      });
      this.copyForm = {
        targetParentId: "",
        includeChildren: false,
      };
    },
    async moveDocument() {
      const params = {
        projectId: this.get_pid(),
        documentId: this.checkedNode.documentId,
        data: {
          targetProjectId: this.moveForm.targetProject,
          targetParentId: this.moveForm.fatherDocument,
          deleteOrigin: !this.moveForm.keepSource,
          includeChildren: this.moveForm.includeChildren,
        },
      };

      this.moveDialogVisible = false;
      await copyDocument(params);
      this.$message({
        type: "success",
        message: this.$t("knowledge.PageMovedSuccessfully"),
      });
      this.moveForm = {
        targetProject: "",
        fatherDocument: "",
        keepSource: true,
        includeChildren: false,
      };
    },
    getAllProgram() {
      const params = {
        jobAuthority: "KNOWLEDGE_ACCESS",
        accountId: this.userAccountId,
      };
      this.$store.getters.t_joined_projects.forEach((e) => {
        this.program_name_list.push(e.projectName);
        this.avatar_p.push(e.avatar);
        this.id_list.push(e.projectId); //项目ID
        this.type_list.push(e.type); //项目类型
        this.tenantid_list.push(e.tenantId);
      });
      getAuthProjects(params).then((res) => {
        res.forEach((e) => {
          this.filter_id_list.push(e.projectId); //项目ID
        });
      });
      const params2 = {
        projectId: this.get_pid(),
      };
      // getDocuments(params2).then((res) => {
      //   this.documentList = res;
      // });
    },
    getTargetDocument() {
      this.targetParentList = [];
      this.moveForm.fatherDocument = "";
      const params = {
        projectId: this.moveForm.targetProject,
      };
      getDocuments(params).then((res) => {
        this.targetParentList = res;
      });
    },
    // 另存为模板
    openSaveTemplateDialog() {
      this.templateFormDialog = true;
    },
    closeTemplateDialog(formName) {
      this.templateFormDialog = false;
      this.$refs[formName].resetFields();
    },
    submitTemplateForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let subMitHtml = this.html;
          // 创建DOM解析器
          let parser = new DOMParser();
          // 将HTML字符串转换为DOM对象
          let doc = parser.parseFromString(subMitHtml, "text/html");
          // 选取所有的class为"my-at"的元素
          let elementsToRemove = doc.querySelectorAll(".my-at");
          // 遍历这些元素，并从其父节点中移除
          elementsToRemove.forEach((element) => {
            element.parentNode.removeChild(element);
          });
          // 这一步用来重新生成完整的 HTML 字符串
          let serializer = new XMLSerializer();
          let updatedHtmlString = serializer.serializeToString(doc.body);
          // 对updatedHtmlString进行处理，移除额外的<body></body>标签
          updatedHtmlString = updatedHtmlString.replace(
            /(^\s*<body[^>]*>)|(<\/body>\s*$)/gi,
            ""
          );
          const data = {
            knowledgeTemplateName: this.saveTemplateForm.name,
            description: this.saveTemplateForm.desc,
            content: updatedHtmlString,
          };
          createTemplate(data).then((res) => {
            this.templateFormDialog = false;
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style lang="scss">
.question-badge {
  .el-badge__content {
    left: 8px;
    text-align: center;
    padding: 0;
  }
  .el-badge__content {
    background-color: #f56c6c;
    width: 18px;
    height: 16px;
    border-radius: 10px;
    color: #fff;
    display: flex;
    font-size: 12px;
    padding: 0 6px;
    text-align: center;
    white-space: nowrap;
    align-items: center;
    justify-content: center;
    border: none;
  }
}
.button-danger {
  color: #fff !important;
  background-color: #f56c6c !important;
  border-color: #f56c6c !important;
}
</style>
<style scoped lang="scss">
.upexcel {
  p {
    font-size: 1em;
    line-height: 140%;
  }
}

.tool-wrap {
  text-align: left;
  min-width: fit-content;
  flex-grow: 1;
  text-align: right;
  position: relative;
  display: flex;
  align-items: flex-start;
  ::v-deep .el-drawer__header {
    font-size: 24px;
    color: rgba(56, 56, 56, 1);
    margin-bottom: 22px;
  }

  ::v-deep .el-drawer {
    background-color: rgba(241, 242, 246, 1);
  }

  ::v-deep .el-drawer__body {
    padding: 0 20px;
    /*no*/
  }

  .question-list {
    & > div {
      background-color: #fff;
      border-radius: 10px;
      padding: 24px;
      margin-bottom: 16px;

      & > div:first-of-type {
        display: flex;
        font-size: 16px;

        & > div:first-of-type {
          width: 5%;
        }

        & > div:last-of-type {
          width: 95%;
        }
      }

      & > div:last-of-type {
        display: flex;
        margin-top: 20px;
        justify-content: left;
        color: rgba(166, 166, 166, 1);

        & > div {
          margin: 0 16px 0 0;
        }
      }
    }
  }

  & > span {
    &:nth-of-type(1) {
      display: inline-block;
      color: rgba(48, 100, 143, 1);
      font-size: 16px;
      vertical-align: middle;
      cursor: pointer;
      vertical-align: middle;
    }

    &:nth-of-type(2) {
      display: inline-block;
      line-height: 50px;
      color: rgba(48, 100, 143, 1);
      margin-right: 20px;
      font-size: 16px;
      vertical-align: middle;
      cursor: pointer;
    }
  }

  .loading_wrap {
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    //width: 80%;
    //margin: 0 auto;

    & > div {
      display: flex;
      align-items: center;
      & > div {
        &:nth-of-type(1) {
          margin-right: 8px;
        }

        & > p {
          margin: 0;
          text-align: left;

          &:nth-of-type(1) {
            font-size: 20px;
            color: rgba(0, 0, 0, 1);
          }

          &:nth-of-type(2) {
            font-size: 14px;
            line-height: 16px;
            opacity: 0.5;
          }
        }
      }
    }
  }

  .authority-list {
    & > span {
      display: inline-block;

      &:first-of-type {
        width: 30%;
        margin-right: 5%;
      }

      &:nth-of-type(2) {
        width: 55%;
        margin-right: 5%;
      }

      &:nth-of-type(3) {
        width: 5%;
        cursor: pointer;
        vertical-align: middle;
      }
    }
  }

  .internetLink {
    text-align: left;
    margin-bottom: 24px;

    &__bg {
      margin-right: 0.0625rem;
      line-height: 0.1719rem;
      background: #ebebeb;
      padding: 4px 0;
      border-radius: 0.0208rem;
    }
  }
}

.status_todo {
  border: 1px solid rgba(204, 204, 204, 1);

  ::v-deep .el-input__inner {
    color: rgba(204, 204, 204, 1);
  }
}

.status_progress {
  border: 1px solid rgba(49, 96, 137, 1);

  ::v-deep .el-input__inner {
    color: rgba(49, 96, 137, 1);
  }
}

.status_done {
  border: 1px solid rgba(67, 207, 124, 1);

  ::v-deep .el-input__inner {
    color: rgba(67, 207, 124, 1);
  }
}

.status_abandon {
  border: 1px solid rgba(204, 204, 204, 1);
  background-color: rgba(204, 204, 204, 1);

  ::v-deep .el-input__inner {
    color: #fff;
  }
}

.status {
  // width: 135px;
  height: 32px;
  border-radius: 4px;
  // color: #fff;
  font-size: 14px;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;

  ::v-deep .el-select__caret {
    color: #000;
  }
}
</style>
