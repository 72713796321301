<template>
  <div class="title-wrap">
    <el-tooltip
      class="item"
      effect="dark"
      placement="top-start"

    >
          <!-- :disabled="!this.is_show_origin_doc" -->
      <div slot="content" style="cursor: pointer" @click="route_origin">
        <!-- {{ "Copy from " + this.originTitle }} -->
        {{ checkedNode ? checkedNode.label : "" }}
      </div>
      <div
        class="title"
        style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;max-width: 700px;"
      >
        {{ checkedNode ? checkedNode.label : "" }}
      </div>
    </el-tooltip>
    <div class="title_status">
      <mindStatus
        v-if="titleMindData.length && checkedNode"
        :is-document="false"
        :mind="titleMindData[0]"
        :status-list="statusList"
      ></mindStatus>
    </div>
  </div>
</template>

<script>
import mindStatus from "./mindStatus.vue";
import { getSourceDocument } from "@/network/knowledge";
export default {
  components: {
    mindStatus,
  },
  props: {
    checkedNode: {
      type: Object,
      default() {
        return null;
      },
    },
    titleMindData: {
      type: Array,
      default() {
        return [];
      },
    },
    statusList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      tips: false,
      originProjectId: "",
      originDocumentId: "",
      originTitle: "",
      is_show_origin_doc: false,
    };
  },
  watch: {
    titleMindData(newval) {
      this.tips = newval.length;
    },
    checkedNode(newVal) {
      this.getOriginDoc();
    },
  },
  mounted() {
    this.getOriginDoc();
  },
  methods: {
    getOriginDoc() {
      const params = {
        projectId: this.get_pid(),
        documentId: this.checkedNode.documentId,
      };
      getSourceDocument(params).then((res) => {
        this.is_show_origin_doc = res.sourceDocumentId;
        this.originProjectId = res.sourceProjectId;
        this.originDocumentId = res.sourceDocumentId;
        this.originTitle = res.title;
      });
    },
    route_origin() {
      const { href } = this.$router.resolve({
        path: `/${this.originProjectId}/knowledge?documentId=${this.originDocumentId}&title=${this.originTitle}`,
      });
      window.open(href, "_blank");
    },
  },
};
</script>

<style scoped lang="scss">
.title-wrap {
  flex-shrink: 1;
  min-width: 0; 
  // padding-left: 30px;
  text-align: left;
  min-height: 32px;
  width: 100%;
  position: relative;
  .title {
    font-size: 32px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    // display: inline-block;
  }
  .title_status {
    position: absolute;
    z-index: 9;
  }
  .tipsPosition {
    line-height: 24px;
    /*no*/
    left: 200px;
    /*no*/
  }
}
</style>
