<template>
  <div
    :style="{
      width: isCollapse ? '0' : '20%',
      height: '100%',
      position: 'relative',
    }"
  >
    <div v-show="!isCollapse" class="right-template">
      <div class="right-template-header">
        <p
          style="margin-bottom: 15px; height: auto"
          class="right-template-title"
        >
          {{ $t("knowledge.template") }}
        </p>
        <p style="text-align: right; cursor: pointer" @click="collpaseAside">
          <i
            class="iconfont icon-zhedie1"
            style="font-size: 24px; margin-right: 12px; color: rgba(0, 0, 0, 1)"
          ></i>
        </p>
      </div>

      <!-- 搜索模块 -->
      <div class="right-template-searchBox">
        <el-input
          v-model="searchKey"
          style="
            width: 16vw;
            padding-right: 30px;
            box-sizing: border-box;
            height: 30px;
          "
          suffix-icon="el-icon-search"
          class="search_ipt"
          :placeholder="$t('homeTopBar.search')"
          @input="searchFuzzy(searchKey)"
        >
        </el-input>
        <!-- 筛选选项 -->
        <div ref="right_select_box" class="right-template-searchBox-select">
          <span
            v-for="(item, index) in [
              ...selectList.slice(0, 5),
              selectList[selectList.length - 1],
            ]"
            :key="index"
          >
            <el-popover
              class="my-el-popover"
              placement="top"
              width="300"
              trigger="hover"
              :disabled="item.id != 'more'"
            >
              <div class="moreBox">
                <span
                  v-for="(item2, index2) in [
                    ...selectList.slice(5, selectList.length - 1),
                  ]"
                  :key="index2"
                  style="padding: 7px 18px 7px 18px"
                  @click="select($event, item2)"
                  >{{ item2.name }}&nbsp;{{
                    preInstallNum[item2.id] == 0 ? "" : preInstallNum[item2.id]
                  }}</span
                >
              </div>
              <span
                slot="reference"
                class="my-span"
                :class="item.id == 'myCreations' ? 'selected-active' : ''"
                style="padding: 7px 18px 7px 18px"
                @click="select($event, item)"
                >{{ item.name }}&nbsp;{{
                  preInstallNum[item.id] == 0 ? "" : preInstallNum[item.id]
                }}</span
              >
            </el-popover>
          </span>
        </div>
      </div>
      <!-- 模板列表 -->
      <div class="templateList" ref="templateList" v-loading="listLoading">
        <div
          class="templateList-item"
          v-for="(item, index) in templateList"
          :key="index"
          @click="showDetail(item)"
        >
          <div class="templateList-item-title">
            <div>{{ item.knowledgeTemplateName }}</div>
            <!-- 模板 更多 -->
            <!-- 内置模板不允许删除 -->
            <div
              v-if="
                selectedMenu.id != 'PROJECT_MANAGEMENT' &&
                selectedMenu.id != 'R_D_DESIGN' &&
                selectedMenu.id != 'PRODUCT_TEST' &&
                selectedMenu.id != 'BUSINESS_ANALYSE' &&
                selectedMenu.id != 'FINANCIAL_MANAGEMENT' &&
                selectedMenu.id != 'HUMAN_RESOURCES' &&
                selectedMenu.id != 'OTHERS'
              "
              style="cursor: pointer"
            >
              <el-dropdown
                @command="
                  worksapcehandleClick($event, item.knowledgeTemplateId)
                "
                size="medium"
                ref="messageDrop"
              >
                <div class="head-img" id="right-header-dot">
                  <i
                    class="el-icon-more"
                    style="color: rgba(112, 112, 112, 1)"
                  ></i>
                </div>
                <el-dropdown-menu slot="dropdown" class="menu-item">
                  <el-dropdown-item command="delete">
                    <i
                      class="iconfont icon-delete"
                      style="cursor: pointer; rgba(112, 112, 112, 1); font-size: 12px"
                    />{{ $t("btn.deleteBtn") }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
          <p v-if="item.description">
            {{ item.description }}
          </p>
          <p class="userNum">
            <el-rate
              v-model="item.rating"
              text-color="#004caa"
              disabled-void-color="transparent"
              disabled
            >
            </el-rate>
            <span
              :style="{
                transform:
                  'translateX(' +
                  (-(5 - item.rating) * 18 - (5 - item.rating - 1) * 6) +
                  'px)',
              }"
              >{{
                item.rating == 0
                  ? ""
                  : parseFloat(item.rating).toFixed(1) +
                    "&nbsp;&nbsp;&nbsp;&nbsp;"
              }}{{ item.usedCount }}{{ $t("knowledge.userNum") }}</span
            >
          </p>
        </div>
      </div>
      <!-- 模板详情卡片 -->
      <div ref="cardBox" style="position: absolute; right: 100%">
        <el-card
          class="box-card"
          v-if="cardVisible"
          element-loading-text="Loading"
          element-loading-background="rgba(0, 0, 0, 0.7)"
          v-loading="detailLoading"
        >
          <div slot="header" class="header clearfix">
            <div class="header-left">
              <span
                style="
                  width: 5%;
                  display: inline-block;
                  cursor: pointer;
                  margin-right: 10px;
                "
              >
                <i
                  v-if="detail.collection"
                  class="iconfont icon-shoucangClick"
                  style="color: rgb(255, 195, 0); font-size: 19px"
                  @click="toggleShoucang()"
                />
                <i
                  v-else
                  class="iconfont icon-shoucang"
                  style="font-size: 22px"
                  @click="toggleShoucang()"
                />
              </span>
              <el-tooltip
                effect="dark"
                :content="detail.knowledgeTemplateName"
                :disabled="!isShowToolTip"
                placement="top"
              >
                <div
                  class="detailTitle"
                  @mouseenter="(event) => ifShowToolTip(event)"
                >
                  {{ detail.knowledgeTemplateName }}
                </div>
              </el-tooltip>
            </div>
            <div class="btnBox">
              <el-button
                v-if="false"
                type="default"
                style="
                  color: rgba(47, 100, 181, 1);
                  border: 1px solid rgba(47, 100, 181, 1);
                  outline: none;
                "
                >上架商店</el-button
              >
              <el-button
                type="default"
                style="
                  color: rgba(47, 100, 181, 1);
                  border: 1px solid rgba(47, 100, 181, 1);
                  outline: none;
                "
                @click="goComment"
                >{{ $t("knowledge.evaluate") }}</el-button
              >
              <el-button
                type="primary"
                style="
                  margin-left: 10px;
                  background: rgba(47, 100, 181, 1);
                  color: rgba(255, 255, 255, 1);
                  border: 1px solid rgba(47, 100, 181, 1);
                  outline: none;
                "
                @click="useIt(detail.knowledgeTemplateId)"
                >{{ $t("templateLibrary.use") }}</el-button
              >
            </div>
          </div>
          <div class="text" ref="text">
            <div v-html="detail.content" id="show-tiptap-docunent-zdh"></div>
          </div>
          <!-- 评价 -->
          <div class="comment" ref="comment">
            <div class="comment-hide">
              <span v-if="show_top" @click="commentShow"
                ><i
                  class="iconfont icon-fold_down"
                  style="color: #000; font-size: 12px"
                />
              </span>
              <span v-else @click="commentShow"
                ><i
                  class="iconfont icon-fold_up"
                  style="color: #000; font-size: 12px"
                />
              </span>
            </div>
            <div class="comment-title">
              <span class="title">{{ $t("knowledge.evaluate") }}&ensp;</span>
              <span class="comment-num">{{ detail.comments.length }}</span>
            </div>
            <div class="commentList" ref="commentList">
              <!-- 没有评价时显示 -->
              <div class="noComment" v-if="detail.comments.length == 0">
                <span>{{ $t("knowledge.p1") }}</span
                ><span class="goComment" @click="goComment">{{
                  $t("knowledge.evaluate")
                }}</span>
              </div>
              <div
                class="commentItem"
                v-for="(c, index) in detail.comments"
                :key="index"
              >
                <!-- 每一个评价的头部 -->
                <div style="display: flex; align-items: center">
                  <span
                    style="
                      display: flex;
                      margin-right: 5px;
                      color: rgba(128, 128, 128, 1);
                    "
                  >
                    <img class="userIcon" :src="c.usrLogo" alt="" />
                  </span>
                  <i
                    v-for="(item, index) in new Array(c.rate)"
                    :key="index"
                    class="iconfont icon-shoucangClick"
                    style="color: rgb(255, 195, 0); font-size: 12px"
                    @click="toggleShoucang($event)"
                  />
                  <span class="comment-date">{{ c.createdAt }}</span>
                </div>
                <!-- 每一个评价的具体内容 -->
                <p
                  :style="{
                    color: c.comment
                      ? 'rgba(56, 56, 56, 1);'
                      : 'rgba(128, 128, 128, 1)',
                    'font-size': '16px',
                  }"
                >
                  {{ c.comment ? c.comment : $t("knowledge.p2") }}
                </p>
              </div>
            </div>
          </div>
        </el-card>
      </div>
    </div>
    <!-- 展开符号 -->
    <div v-show="isCollapse" class="left-menu-hide" @click="collpaseAside">
      <div class="showCommentBtn">
        <i
          class="iconfont icon-shouqi1"
          style="color: rgb(145, 147, 153);font-size: 20px;font-weight: 600;"
        ></i>
      </div>
      
    </div>
    <el-dialog
      :visible.sync="commentFormDialog"
      :close-on-click-modal="false"
      :show-close="true"
      width="25%"
      :title="$t('knowledge.evaluate')"
      @open="openCommentDialog"
      @close="resetForm('saveCommentForm')"
    >
      <el-form
        ref="saveCommentForm"
        :rules="saveCommentRules"
        :model="saveCommentForm"
        label-width="110px"
        label-position="left"
      >
        <el-form-item
          :label="$t('knowledge.vrate')"
          class="myRate"
          prop="rate"
          style="display: flex; align-items: center"
        >
          <el-rate
            style="display: flex; align-items: center"
            v-model="saveCommentForm.rate"
            text-color="#004caa"
            :texts="[
              $t('knowledge.degree1'),
              $t('knowledge.degree2'),
              $t('knowledge.degree3'),
              $t('knowledge.degree4'),
              $t('knowledge.degree5'),
            ]"
            show-text
          >
          </el-rate>
        </el-form-item>
        <el-form-item :label="$t('gantt.Desc')" prop="desc">
          <el-input
            :placeholder="$t('knowledge.p3')"
            v-model="saveCommentForm.desc"
          ></el-input>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="closeRateDialog('saveCommentForm')">{{
          $t("btn.cancelBtn")
        }}</el-button>
        <el-button type="primary" @click="submitRateForm('saveCommentForm')">{{
          $t("menu.release")
        }}</el-button>
      </div>
    </el-dialog>
    <!-- 删除模板浮窗 -->
    <el-dialog
      :title="$t('review.tip11')"
      :visible.sync="deleteDialog"
      :close-on-click-modal="false"
      :show-close="true"
      width="25%"
    >
      <div style="display: flex">
        <i
          style="font-size: 20px; color: rgb(255, 195, 0)"
          class="iconfont icon-jingshi-tianchong"
        ></i>
        <span style="float: left; text-align: start; padding-left: 10px">
          {{ $t("knowledge.deleteTemplateInfo") }}
        </span>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="danger" @click="deleteTemplate">{{
          $t("addMyColla.table.title10")
        }}</el-button>
        <el-button @click="deleteDialog = false">{{
          $t("btn.cancelBtn")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import vmson from "@/utils/vmson";
import {
  getMyCreations,
  getMyCollections,
  getTeamTemplate,
  getPreInstall,
  collectTemplate,
  cancelCollectTemplate,
  deleteTemplate,
  fuzzyTemplate,
  getTemplateComment,
  createTemplateComment,
  getPreInstallNum,
} from "@/network/knowledgeTemplate/index.js";
import { textRange } from "@/utils/textRange";
import { mapGetters } from "vuex";

export default {
  data() {
    // 验证是否评分
    var checkRate = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t("knowledge.p4")));
      } else {
        callback();
      }
    };
    return {
      isCollapse: true,
      selectList: [
        { name: this.$t("knowledge.myCreations"), id: "myCreations", num: 0 },
        {
          name: this.$t("knowledge.myCollections"),
          id: "myCollections",
          num: 0,
        },
        { name: this.$t("knowledge.myPurchase"), id: "myPurchase", num: 0 },
        { name: this.$t("knowledge.teamTemplate"), id: "teamTemplate", num: 0 },
        {
          name: this.$t("knowledge.PROJECT_MANAGEMENT"),
          id: "PROJECT_MANAGEMENT",
          num: 0,
        },
        { name: this.$t("knowledge.R_D_DESIGN"), id: "R_D_DESIGN", num: 0 },
        { name: this.$t("knowledge.PRODUCT_TEST"), id: "PRODUCT_TEST", num: 0 },
        {
          name: this.$t("knowledge.BUSINESS_ANALYSE"),
          id: "BUSINESS_ANALYSE",
          num: 0,
        },
        {
          name: this.$t("knowledge.FINANCIAL_MANAGEMENT"),
          id: "FINANCIAL_MANAGEMENT",
          num: 0,
        },
        {
          name: this.$t("knowledge.HUMAN_RESOURCES"),
          id: "HUMAN_RESOURCES",
          num: 0,
        },
        { name: this.$t("knowledge.OTHERS"), id: "OTHERS", num: 0 },
        { name: this.$t("knowledge.more"), id: "more", num: 0 },
      ],
      preInstallNum: {},
      selectedMenu: {
        name: this.$t("knowledge.myCreations"),
        id: "myCreations",
      },
      searchKey: "",
      templateList: [],
      pageInfo: {
        page: 0,
        size: 20,
      },
      // 列表loading
      listLoading: false,
      // 详情loading
      detailLoading: true,
      // 记录当前总数量
      totalElements: 0,
      // 记录表格dom
      listDom: "",
      scrollDistance: 1000,
      // 防抖
      timeOut: null,

      visible: false,
      cardVisible: false,
      // 模板详情信息
      detail: {
        title: "",
        content: "",
        loading: true,
        type: this.$t("knowledge.myCreations"),
        collection: false,
        comments: [],
      },
      show_top: false,
      commentFormDialog: false,
      saveCommentForm: {
        rate: 0,
        desc: "",
      },

      saveCommentRules: {
        rate: [{ required: true, validator: checkRate, trigger: "blur" }],
      },
      deleteDialog: false,
      deleteKnowledgeTemplateId: "",
      isShowToolTip: false,
    };
  },
  computed: {
    ...mapGetters(["knowledge_edit_mode"]),
  },
  watch: {
    scrollDistance(val) {
      if (val < 1 && !this.searchKey) {
        this.pageInfo.page++;
        this.getData(this.selectedMenu);
      } else if (val < 1 && this.searchKey) {
        this.listLoading = true;
        this.pageInfo.page++;
        let params = {
          ...this.pageInfo,
          fuzzyKey: { fuzzyKey: this.searchKey },
        };
        fuzzyTemplate(params).then((res) => {
          this.totalElements = res.totalElements;
          this.templateList.push(...res.content);
          this.listLoading = false;
        });
      }
    },
  },
  methods: {
    // 判断详情的标题是否超出宽度
    ifShowToolTip(event) {
      this.isShowToolTip = textRange(event.target);
    },
    collpaseAside() {
      this.isCollapse = !this.isCollapse;
    },
    // 模糊查询模板
    async searchFuzzy() {
      this.listDom.scrollTop = 0;
      if (this.searchKey == "") {
        this.pageInfo = {
          page: 0,
          size: 20,
        };
        this.templateList = [];
        this.getData(this.selectedMenu);
      } else {
        this.listLoading = true;
        let params = {
          page: 0,
          size: 20,
          fuzzyKey: { fuzzyKey: this.searchKey },
        };
        const res = await fuzzyTemplate(params);
        this.totalElements = res.totalElements;
        this.pageInfo = {
          page: 0,
          size: 20,
        };
        this.templateList = res.content;
        this.listLoading = false;
      }
    },
    // 收藏
    toggleShoucang() {
      let knowledgeTemplateId = this.detail.knowledgeTemplateId;
      if (!this.detail.collection) {
        collectTemplate(knowledgeTemplateId).then((res) => {
          this.detail.collection = true;
        });
      } else {
        cancelCollectTemplate(knowledgeTemplateId).then((res) => {
          this.detail.collection = false;
        });
      }
    },
    // 获取评论
    getTemplateComment(knowledgeTemplateId) {
      getTemplateComment(knowledgeTemplateId).then((res) => {});
    },
    // 查看模板详情
    showDetail(item) {
      this.detail = item;
      this.detail.comments = [];
      this.detailLoading = true;
      getTemplateComment(this.detail.knowledgeTemplateId).then((res) => {
        this.detail.comments = res;
        this.detail.comments.forEach((item) => {
          item.usrLogo = require("../../../../assets/img/头像/" +
            (Math.floor(Math.random() * 19) + 1) +
            ".png");
        });
        this.detailLoading = false;
        this.cardVisible = true;
        document.addEventListener("click", this.hidePanel, false);
      });
    },
    // 隐藏模板详情
    hidePanel(e) {
      if (
        !this.$refs.templateList.contains(e.target) &&
        !this.$refs.cardBox.contains(e.target)
      ) {
        this.cardVisible = false;
        this.detailLoading = true;
        document.removeEventListener("click", this.hidePanel, false);
      }
    },

    // 伸展评论区
    commentShow() {
      this.show_top = !this.show_top;
      if (this.show_top) {
        this.$refs.text.style.height = "0";
        this.$refs.comment.style.height = "70vh";
        this.$refs.commentList.style.height = "calc(59vh - 13px)";
      } else {
        this.$refs.text.style.height = "57vh";
        this.$refs.comment.style.height = "13vh";
        this.$refs.commentList.style.height = "calc(11vh - 23px)";
      }
    },
    openCommentDialog() {
      document.removeEventListener("click", this.hidePanel, false);
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      setTimeout(() => {
        document.addEventListener("click", this.hidePanel, false);
      }, 300);
    },
    closeRateDialog(formName) {
      this.commentFormDialog = false;
      this.$refs[formName].resetFields();
    },
    submitRateForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = {
            knowledgeTemplateId: this.detail.knowledgeTemplateId,
            content: {
              comment: this.saveCommentForm.desc,
              rate: this.saveCommentForm.rate,
            },
          };
          createTemplateComment(data).then((res) => {
            this.detailLoading = true;
            getTemplateComment(this.detail.knowledgeTemplateId).then((res) => {
              this.detail.comments = res;
              this.detail.comments.forEach((item) => {
                item.usrLogo = require("../../../../assets/img/头像/" +
                  (Math.floor(Math.random() * 19) + 1) +
                  ".png");
              });
              this.detailLoading = false;
              this.commentFormDialog = false;
              this.listDom.scrollTop = 0;
              this.pageInfo = {
                page: 0,
                size: 20,
              };
              this.templateList = [];
              this.getData(this.selectedMenu);
            });
          });
        } else {
          return false;
        }
      });
    },
    goComment() {
      this.commentFormDialog = true;
    },

    worksapcehandleClick(command, knowledgeTemplateId) {
      if (command === "delete") {
        this.deleteKnowledgeTemplateId = knowledgeTemplateId;
        this.deleteDialog = true;
      }
    },
    // 删除模板
    deleteTemplate() {
      deleteTemplate(this.deleteKnowledgeTemplateId).then((res) => {
        this.listDom.scrollTop = 0;
        this.pageInfo = {
          page: 0,
          size: 20,
        };
        this.templateList = [];
        this.getData(this.selectedMenu);
        this.getEachTypeTemplateNum();
        this.deleteDialog = false;
      });
    },
    // 使用模板
    useIt(id) {
      // document.getElementsByClassName('ql-editor')[0].innerHTML =  document.getElementsByClassName('ql-editor')[0].innerHTML.concat(this.$refs.text.innerHTML)
      // const data = {
      //   content: this.$refs.text.innerHTML,
      //   knowledgeTemplateId: id,
      //   callback: () => {
      //     this.getNewUsed();
      //   },
      // };
      console.log('useuse');
      vmson.$emit("useTemplate_tiptap", this.$refs.text.innerHTML);
      
      // vmson.$emit("useTemplate", data);
    },
    // 使用模板后在父组件中调用该方法获取最新数据
    getNewUsed() {
      this.listDom.scrollTop = 0;
      this.pageInfo = {
        page: 0,
        size: 20,
      };
      this.templateList = [];
      this.getData(this.selectedMenu);
    },

    // 点击不同的筛选按钮
    select(e, item) {
      const clearList = document.getElementsByClassName("selected-active");
      if (item.id == "more") {
        return;
      }
      if (clearList.length > 0) {
        clearList.forEach((element) => {
          element.classList.remove("selected-active");
        });
      }
      if (e.target.classList.contains("selected-active")) {
        e.target.classList.remove("selected-active");
      } else {
        e.target.classList.add("selected-active");
      }
      this.searchKey = "";
      this.listDom.scrollTop = 0;
      this.selectedMenu = item;
      this.pageInfo = {
        page: 0,
        size: 20,
      };
      this.templateList = [];
      this.getData(item);
    },
    // 初始化模板列表
    initTemplateList() {
      this.selectedMenu = {
        name: this.$t("knowledge.myCreations"),
        id: "myCreations",
      };
      const wh = window.innerHeight;
      if (this.$refs["templateList"]) {
        this.$refs["templateList"].removeEventListener(
          "scroll",
          this.listScroll
        );
        this.$refs["templateList"].addEventListener("scroll", this.listScroll);
        this.listDom = this.$refs["templateList"];
        this.listDom.scrollTop = 0;
        this.pageInfo = {
          page: 0,
          size: 20,
        };
        this.templateList = [];
        this.getData(this.selectedMenu);
      }
    },
    // 获取所有类型的模板数量
    async getEachTypeTemplateNum() {
      await getPreInstallNum().then((res) => {
        res.forEach((item) => {
          this.preInstallNum = {
            ...this.preInstallNum,
            [item.preInstallKnowledgeTemplateType]: item.count,
          };
        });
      });
    },
    // 获取不同类型的模板列表
    getData(item) {
      this.totalElements = 0;
      if (item.id === "myCreations") {
        this.getMyCreations();
      } else if (item.id === "myCollections") {
        this.getMyCollections();
      } else if (item.id === "myPurchase") {
        this.getMyPurchase();
      } else if (item.id === "teamTemplate") {
        this.getTeamTemplate();
      } else {
        this.getPreInstall();
      }
    },
    // 获取我的模板
    getMyCreations() {
      this.listLoading = true;
      getMyCreations(this.pageInfo).then((res) => {
        this.templateList.push(...res.content);
        this.totalElements = res.totalElements;
        this.listLoading = false;
      });
    },
    // 获取我的收藏
    getMyCollections() {
      this.listLoading = true;
      getMyCollections(this.pageInfo).then((res) => {
        this.templateList.push(...res.content);
        this.totalElements = res.totalElements;
        this.listLoading = false;
      });
    },
    // 获取我购买的模板
    getMyPurchase() {},
    // 获取团队模板
    getTeamTemplate() {
      this.listLoading = true;
      getTeamTemplate(this.pageInfo).then((res) => {
        this.templateList.push(...res.content);
        this.totalElements = res.totalElements;
        this.listLoading = false;
      });
    },
    // 获取内置模板
    getPreInstall() {
      this.listLoading = true;
      const params = {
        ...this.pageInfo,
        templateType: this.selectedMenu.id,
      };
      getPreInstall(params).then((res) => {
        this.templateList.push(...res.content);
        this.totalElements = res.totalElements;
        this.listLoading = false;
      });
    },
    // 滚动事件
    listScroll() {
      if (this.totalElements <= this.templateList.length) {
        return;
      }
      this.scrollDistance =
        this.listDom.scrollHeight -
        this.listDom.scrollTop -
        this.listDom.clientHeight -
        2;
    },
  },
  mounted() {
    this.getEachTypeTemplateNum();
    vmson.$on("closeTemplate", (data) => {
      this.isCollapse = data;
    });
    if (this.knowledge_edit_mode === "add") {
      this.isCollapse = false;
    }
    this.$nextTick(() => {
      this.initTemplateList();
      const _that = this;
      if (_that.$refs.cardBox) {
        if (window.innerHeight < 780) {
          _that.$refs.cardBox.style.top = `calc(0% - ${
            ((780 - window.innerHeight) * 5) / 8
          }px)`;
        } else {
          _that.$refs.cardBox.style.top = "0%";
        }
      }
      window.onresize = function () {
        if (_that.$refs.cardBox) {
          if (window.innerHeight < 780) {
            _that.$refs.cardBox.style.top = `calc(0% - ${
              ((780 - window.innerHeight) * 5) / 8
            }px)`;
          } else {
            _that.$refs.cardBox.style.top = "0%";
          }
        }
      };
    });
  },
  beforeDestroy() {
    if (this.$refs["templateList"]) {
      this.$refs["templateList"].removeEventListener("scroll", this.listScroll);
      window.onresize = null;
    }
  },
};
</script>

<style lang="scss" scoped>
.right-template {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  // overflow-y: scroll;
  text-align: left;
  padding-left: 20px;
  padding-top: 20px;
  border-left: 1px solid rgba(229, 229, 229, 1);
  background-color: #00000005;
  .right-template-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 25px;
    margin-bottom: 15px;
    & > p {
      height: 25px;
      line-height: 25px;
    }
    & > p:first-child {
      align-self: center;
    }
    .right-template-title {
      font-size: 20px;
      font-weight: 700;
      letter-spacing: 0px;
      line-height: 28.96px;
      color: rgba(0, 0, 0, 1);
      text-align: left;
      vertical-align: top;
    }
  }
  .right-template-searchBox {
    .el-input--suffix {
      position: relative !important;
    }
    .right-template-searchBox-select {
      display: flex;
      flex-wrap: wrap;
      margin-top: 20px;
      & > span {
        .selected-active {
          background: rgba(222, 237, 255, 1) !important;
          border: 1px solid rgba(42, 130, 228, 1) !important;
          color: rgba(42, 130, 228, 1) !important;
        }
        .my-span {
          cursor: pointer;
          display: inline-block;
          margin: 0 12px 10px 0;
          height: 35px;
          opacity: 1;
          border-radius: 6px;
          border: 1px solid rgba(221, 223, 230, 1);
          font-size: 16px;
          font-weight: 400;
          letter-spacing: 0px;
          line-height: 20.27px;
          color: rgba(96, 98, 102, 1);
        }
        .my-span:hover {
          background: rgba(222, 237, 255, 1);
          border: 1px solid rgba(42, 130, 228, 1);
          color: rgba(42, 130, 228, 1);
        }
      }
    }
  }

  .templateList {
    flex: 1;
    margin-top: 8px;
    height: 50vh;
    overflow-y: scroll;
    .templateList-item {
      margin-bottom: 20px;
      padding: 5px;
      width: 90%;
      cursor: pointer;
      .templateList-item-title {
        display: flex;
        justify-content: space-between;
        & > div:first-child {
          width: 92%;
          font-size: 16px;
          font-weight: 500;
          letter-spacing: 0px;
          line-height: 23.17px;
          color: rgba(56, 56, 56, 1);
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      & > p {
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 23.17px;
        color: #8080806e;
        display: flex;
        align-items: center;
      }
      .userNum {
        margin-bottom: 0;
        line-height: 20.27px;
        & > span {
          white-space: nowrap;
        }
      }
    }
    .templateList-item:hover {
      background-color: #ebebeb;
    }
  }
}
::v-deep .el-input__suffix {
  position: absolute !important;
  right: 40px !important;
  font-size: 20px !important;
}

.el-popover {
  .moreBox {
    min-height: 90px;
    max-height: 150px;
    overflow-y: auto;
    .selected-active {
      background: rgba(222, 237, 255, 1) !important;
      border: 1px solid rgba(42, 130, 228, 1) !important;
      color: rgba(42, 130, 228, 1) !important;
    }
    & > span {
      cursor: pointer;
      display: inline-block;
      margin: 0 12px 10px 0;
      height: 35px;
      opacity: 1;
      border-radius: 6px;
      border: 1px solid rgba(221, 223, 230, 1);
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0px;
      line-height: 20.27px;
      color: rgba(96, 98, 102, 1);
    }
    & > span:hover {
      background: rgba(222, 237, 255, 1);
      border: 1px solid rgba(42, 130, 228, 1);
      color: rgba(42, 130, 228, 1);
    }
  }
}

::v-deep .el-popper {
  min-width: 270px !important;
  min-height: 150px !important;
}
.left-menu-hide {
  // width: 20px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  text-align: center;

  &:hover {
    background-color: #f0f7ff;
    cursor: pointer;
  }
  .showCommentBtn{
    cursor: pointer;
    position: relative;
    left: -36px;
    top: 10px;
    display: flex;
    align-items: center;
    height: 35px;
    padding-right: 6px;
    padding-left: 8px;
    border-radius: 30px 0 0 30px;
    background-color: white;
    border: 1px solid rgba(229, 229, 229, 1);
    &:hover {
      background-color: #f0f7ff;
      cursor: pointer;
    }
}
}
#show-tiptap-docunent-zdh {
  font-size: 15px;
  text-align: left;
  color: rgb(23, 23, 23);
  font-family: 'Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"';

  .mention {
    font-size: 13px;
    background-color: #a9d5ff;
    border-radius: 4px;
    box-decoration-break: clone;
    color: #1873cc;
    padding: 1px 5px;
  }
  img {
    // display: block;
    height: auto;
    // margin: 1.5em 0;
    max-width: 100%;
    width: 500px;

    &.ProseMirror-selectednode {
      outline: 3px solid #0064ff;
    }
  }
  ul[data-type="taskList"] {
    list-style: none;
    margin-left: 0;
    padding: 0;
    p {
      margin: 5px 0;
    }
    li {
      align-items: flex-start;
      display: flex;
      align-items: center;

      > label {
        margin-right: 0.5em;
        user-select: none;
        width: 20px;
        input {
          // background: red;
        }
      }

      > div {
      }
    }

    input[type="checkbox"] {
      cursor: pointer;
    }

    ul[data-type="taskList"] {
      margin: 0;
    }
  }
  .collaboration-cursor__caret {
    border-left: 1px solid #0d0d0d;
    border-right: 1px solid #0d0d0d;
    margin-left: -1px;
    margin-right: -1px;
    pointer-events: none;
    position: relative;
    word-break: normal;
  }

  .collaboration-cursor__label {
    border-radius: 3px 3px 3px 0;
    color: #0d0d0d;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    left: -0.5px;
    line-height: normal;
    padding: 5px 3px;
    position: absolute;
    top: -2em;
    user-select: none;
    white-space: nowrap;
  }
  ul {
    padding-left: 25px;
  }
  ul li::marker {
    color: #0064ff;
  }
  ol {
    padding-left: 25px;
  }
  ol li::marker {
    color: #0064ff;
  }
  a {
    color: #0064ff;
    cursor: pointer;
    position: relative;

    &:hover {
      border-bottom: 2px solid #0064ff;
    }
  }

  hr {
    border: none;
    border-top: 1px solid #3d251414;
    border-top: 2px dashed #cccccc;
    cursor: pointer;
    margin: 10px 0;

    &.ProseMirror-selectednode {
      border-top: 1px solid #e3eeff;
    }
  }
  mark {
    // background-color: #faf594;
    border-radius: 0.4em;
    box-decoration-break: clone;
    padding: 0.1em 0.3em;
  }
  // 引用样式
  blockquote {
    margin: 0;
    border-left: 3px solid #e5e7eb;
    // margin: 1.5rem 0;
    padding-left: 10px;
    color: rgb(135, 136, 138);
    p {
      margin-top: 2.5px;
      margin-bottom: 2.5px;
    }
  }
  pre {
    background: black;
    border-radius: 5px;
    font-family: "Consolas, Monaco", "Andale Mono", "Ubuntu Mono", monospace;
    margin: 10px 0;
    color: white;
    padding: 20px;
    font-size: 14px;

    code {
      background: none;
      color: inherit;
      // font-size: 0.8rem;
      padding: 0;
    }
    /* Code styling */
    .hljs-comment,
    .hljs-quote {
      color: #616161;
    }

    .hljs-variable,
    .hljs-template-variable,
    .hljs-attribute,
    .hljs-tag,
    .hljs-name,
    .hljs-regexp,
    .hljs-link,
    .hljs-name,
    .hljs-selector-id,
    .hljs-selector-class {
      color: #f98181;
    }

    .hljs-number,
    .hljs-meta,
    .hljs-built_in,
    .hljs-builtin-name,
    .hljs-literal,
    .hljs-type,
    .hljs-params {
      color: #fbbc88;
    }

    .hljs-string,
    .hljs-symbol,
    .hljs-bullet {
      color: #b9f18d;
    }

    .hljs-title,
    .hljs-section {
      color: #faf594;
    }

    .hljs-keyword,
    .hljs-selector-tag {
      color: #70cff8;
    }

    .hljs-emphasis {
      font-style: italic;
    }

    .hljs-strong {
      font-weight: 700;
    }
  }
  /* Table-specific styling */
  table {
    border-collapse: collapse;
    margin: 0;
    overflow: hidden;
    table-layout: fixed;
    width: 100% !important;

    tr{
      height: 25px;
    }

    td,
    th {
      border: 1px solid #dfe1e5;
      box-sizing: border-box;
      min-width: 1em;
      padding: 6px 8px;
      position: relative;
      vertical-align: top;

      > * {
        margin: 0 !important;
      }
    }

    th {
      // background-color: gray;
      font-weight: bold;
      text-align: left;
    }

    .selectedCell:after {
      background: rgb(160 198 255 / 60%);
      content: "";
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      pointer-events: none;
      position: absolute;
      z-index: 2;
    }

    .column-resize-handle {
      background-color: #0064ff;
      bottom: -2px;
      pointer-events: none;
      position: absolute;
      right: -2px;
      top: 0;
      width: 4px;
    }
  }

  mark {
    border-radius: 0;
    padding: 0;
  }

  .tableWrapper {
    margin: 1.5em 0;
    overflow-x: auto;
  }

  &.resize-cursor {
    cursor: ew-resize;
    cursor: col-resize;
  }
}
</style>
<style lang="scss" scoped>
.box-card {
  z-index: 100;
  min-width: 700px;
  height: 81vh;
  ::v-deep .el-card__header {
    padding: 18px 0 6px 0;
  }
  .header {
    height: 38px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .header-left {
      display: flex;
      align-items: center;
      line-height: 38px;
      font-size: 24px;
      font-weight: 500;
      width: calc(50% + 10px);
      .detailTitle {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    .btnBox {
    }
  }
  .text {
    font-size: 14px;
    height: 57vh;
    overflow-y: scroll;
    transition: 0.5s all;
  }
  .comment {
    height: 13vh;
    border-top: 0.5px solid rgba(240, 242, 245, 1);
    transition: 0.5s all;
    .comment-hide {
      text-align: center;
      & > span {
        cursor: pointer;
      }
    }
    .comment-title {
      height: 23px;
      .title {
        font-size: 18px;
        font-weight: 500;
        line-height: 26.06px;
        color: rgba(56, 56, 56, 1);
      }
      .comment-num {
        font-size: 12px;
        font-weight: 400;
        color: rgba(146, 145, 145, 1);
      }
    }
    .commentList {
      overflow-y: scroll;
      margin-top: 13px;
      height: calc(10vh - 23px);
      transition: 0.5s all;
      .noComment {
        .goComment {
          cursor: pointer;
          font-size: 16px;
          font-weight: 400;
          color: rgba(47, 100, 181, 1);
        }
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        color: rgba(166, 166, 166, 1);
      }

      .commentItem {
        margin-bottom: 20px;
        .comment-date {
          margin-left: auto;
          font-size: 12px;
          font-weight: 500;
          color: rgba(201, 201, 201, 1);
        }
        .userIcon {
          width: 25px;
          height: 25px;
        }
      }
    }
  }

  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both;
  }
}
.myRate {
  ::v-deep .el-form-item__content {
    margin-left: 0 !important;
    .el-rate__text {
      margin-left: 10px;
    }
  }
}
</style>
